import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import * as MTZ from 'moment-timezone'

const TimeCounter = ({
  colClassName,
  className,
  date,
  message,
  timeZoneId = 'UTC',
}) => {
  const [time, setTime] = useState('')

  const messageComplete = message === 'Complete booking'
  const formatTime = time => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24))
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((time % (1000 * 60)) / 1000)
    return { days, hours, minutes, seconds }
  }

  useEffect(() => {
    let targetTime = MTZ.utc(date)
      .tz(timeZoneId, true)
      .valueOf()

    if (message === 'Complete booking') {
      const targetTimeUTC = MTZ.utc(date).add(24, 'hours')
      targetTime = targetTimeUTC.tz(timeZoneId, true).valueOf()
    }

    const updateCounter = () => {
      const currentTime = MTZ.tz(timeZoneId).valueOf()
      const timeDifference = messageComplete
        ? currentTime - targetTime
        : targetTime - currentTime

      if (timeDifference < 0 && !messageComplete) {
        setTime('Countdown completed!')
        clearInterval(interval)
        setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 })
      } else {
        setTime(formatTime(Math.abs(timeDifference)))
      }
    }

    const interval = setInterval(updateCounter, 1000)
    updateCounter()

    return () => clearInterval(interval)
  }, [date, messageComplete])

  return (
    <Col className={colClassName || ''}>
      <div
        className={`partner-countdown ${className || ''}`}
        style={
          time.days && time.days > 0
            ? { color: 'black', alignSelf: 'center' }
            : { color: '#d0021b', alignSelf: 'center' }
        }
      >
        <>
          {!!time.days && (
            <>
              <span>{time.days}</span>
              <span>d</span>
            </>
          )}
          {!!time.hours && (
            <>
              <span>{time.hours}</span>
              <span>h</span>
            </>
          )}
          <span>{time.minutes}</span>
          <span>m</span>
          <span>{time.seconds}</span>
          <span>s</span>
        </>
      </div>
    </Col>
  )
}

export default TimeCounter
