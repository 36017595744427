import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { isMobile } from 'helpers'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { completeTrip } from '../actions'
import FormRadio from '../components/FormRadio'

const isMobileBrowser = isMobile()

const CompleteTripModal = ({
  show,
  onHide,
  completeTrip,
  bookingId = null,
  supplierId = null,
  isPartner = false,
  number,
  afterAction = () => {},
}) => {
  const [additionalCharges, setAdditionalCharges] = useState(false)
  const [isCommentEmpty, setIsCommentEmpty] = useState(false)

  const onChangeRadio = async option => {
    setAdditionalCharges(option)
    setIsCommentEmpty(false)
  }
  const onClose = () => {
    onHide()
  }
  const onConfirmation = () => {
    onCompleteTrip()
  }

  const onCompleteTrip = async () => {
    try {
      if (bookingId) {
        if (additionalCharges) {
          var details = document.getElementById('details').innerText

          if (details === '') {
            setIsCommentEmpty(true)
            return
          } else {
            setIsCommentEmpty(false)
          }
        }

        let info = {
          additionalCharges: additionalCharges,
          details: details,
          isPartner: isPartner,
        }
        await completeTrip(info, bookingId, supplierId)
        afterAction()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      size='lg'
      show={show}
      onHide={onClose}
      className='decline-offer-booking-modal modal-90w'
      dialogClassName={
        isMobileBrowser ? 'modal-90w decline-offer' : 'modal-90w '
      }
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg' className='modal-title'>
          {isMobileBrowser && (
            <div className='driver-booking-container-header-booking'>
              <img
                className='driver-back-icon mx-50%'
                src='/images/icon-back.png'
                onClick={() => {
                  onHide()
                }}
                alt=''
              />
              <span style={{ paddingTop: '0.2em' }}>Booking #{number}</span>
              <span></span>
            </div>
          )}
          {!isMobileBrowser && (
            <h1 className='title'>Any additional charges?</h1>
          )}
          <div className='close-btn-wrap'>
            <img
              src={`${
                isMobileBrowser
                  ? '/images/icons/close-icon-black.png'
                  : '/images/icons/modal-close-icon.svg'
              }`}
              alt=''
              height={12}
              width={12}
              style={
                isMobileBrowser
                  ? { marginRight: '12px', marginTop: '10px' }
                  : {}
              }
              onClick={onClose}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isMobileBrowser && <h1 className='title'>Any additional charges?</h1>}
        <p className='modal-description'>
          Select “Yes” if extra charges, like wait time, stops, or service
          extensions, were incurred.
        </p>
        <p
          className='modal-description'
          style={
            isMobileBrowser
              ? { marginBottom: '1rem' }
              : { marginBottom: '2rem' }
          }
        >
          Submit the report within 24 hours of drop-off. Late submissions will
          result in the booking being marked as completed without extra charges.
        </p>
        <div className='modal-options'>
          <FormRadio
            name='no'
            id='no'
            label='No'
            labelClass='options'
            handleChange={() => onChangeRadio(false)}
            checked={!additionalCharges}
          />
          <FormRadio
            name='yes'
            id='yes'
            label='Yes'
            labelClass='options'
            handleChange={() => onChangeRadio(true)}
            checked={additionalCharges}
          />
        </div>
        {additionalCharges ? (
          <div style={{ marginTop: '36px' }}>
            <label> PROVIDE DETAILS* </label>
            <span
              id='details'
              className='form-group notes-expandable-partner'
              as='textarea'
              contentEditable='plaintext-only'
              style={
                isMobileBrowser
                  ? {
                      minHeight: '200px',
                      padding: '0.75rem 0.75rem',
                      fontSize: '14px',
                    }
                  : {
                      marginBottom: 0,
                      padding: '0.75rem 0.75rem',
                      fontSize: '14px',
                    }
              }
              onInput={e => {
                const element = e.target
                element.setAttribute(
                  'data-placeholder-active',
                  element.textContent.trim() === '' ? 'true' : 'false'
                )
              }}
              data-placeholder-active='true'
              data-placeholder='Additional wait time, stop, service extension, etc.'
            ></span>
          </div>
        ) : (
          <></>
        )}
        {isCommentEmpty && (
          <span className='mismatch-text mt-1'>
            Please provide additional details.
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isMobileBrowser ? (
          <Button
            className='select-btn select-vehicle partner-btn-desktop'
            variant='brown'
            onClick={onConfirmation}
            style={{ margin: 0 }}
          >
            Submit
          </Button>
        ) : (
          <div className='driver-booking-cta-footer-assign'>
            <Button
              className='select-btn select-vehicle'
              style={{
                width: '100%',
                marginBottom: '15px',
                fontWeight: '900',
                fontSize: '16px',
                textShadow: '0.25px 0 #ffffff',
              }}
              variant='brown'
              onClick={onConfirmation}
            >
              Submit
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}
export default withRouter(
  connect(null, {
    completeTrip,
  })(CompleteTripModal)
)
