import { defaultPayoutTerms } from '../constants/index'
import { getLabelFromValue } from 'helpers'
import { logger } from 'actions'

export class ChauffeurInitialValues {
  constructor({
    firstName = '',
    lastName = '',
    phoneNumber = '',
    email = '',
    profilePicture = '',
  } = {}) {
    this.firstName = firstName
    this.lastName = lastName
    this.phoneNumber = phoneNumber
    this.email = email
    this.profilePicture = profilePicture
      ? {
          name: profilePicture,
        }
      : null
  }
}

export class CarInitialValues {
  constructor({
    make = {},
    model = {},
    interiorColour = '',
    exteriorColour = '',
    year = '',
    plateNumber = ',',
  } = {}) {
    this.make = make
    this.model = model
    this.interiorColour = interiorColour
    this.exteriorColour = exteriorColour
    this.year = year
    this.plateNumber = plateNumber
  }
}
export class CompanyInitialValues {
  constructor({
    name = '',
    address = '',
    registrationNumber = '',
    notes = '',
    website = '',
    contactDetails = [],
    vehicles = [],
    contactEmail = '',
    contactFullName = '',
    contactPhones = [],
    place = null,
    status = '',
    payoutTerms,
    billing,
  } = {}) {
    this.name = name
    this.address = address
    this.registrationNumber = registrationNumber
    this.notes = notes
    this.website = website
    this.contactEmail = contactEmail
    this.contactFullName = contactFullName
    this.contactDetails = contactDetails ? contactDetails : []
    this.vehicles = vehicles ? vehicles : []
    this.place = place
    this.address = {
      fullAddress: address,
    }
    this.status = status ? status : { label: 'NEW', value: 'new' }
    this.payoutTerms = payoutTerms
      ? {
          label: getLabelFromValue(defaultPayoutTerms, payoutTerms),
          value: payoutTerms,
        }
      : { label: 'Monthly', value: 'payout_term_monthly' }

    this.billing = {
      name: billing.name || '',
      taxNumber: billing.taxNumber || '',
      registrationNumber: billing.registrationNumber || '',
      isTaxRegistered:
        billing.isTaxRegistered === undefined ||
        billing.isTaxRegistered === null
          ? ''
          : billing.isTaxRegistered,
    }
  }
}

export const companyPartnerFormData = async ({
  name = '',
  address = '',
  website = '',
  contactDetails = [],
  place = null,
  addressLine2,
  billing,
}) => {
  try {
    let data = {
      name: name,
      address: address,
      website: website,
      contactDetails: contactDetails ? contactDetails : [],
      place: place,
      addressLine2: addressLine2,
      taxNumber: billing.taxNumber,
      isTaxRegistered: billing.isTaxRegistered,
    }

    if (address) {
      data.address = address.fullAddress || ''
    }

    return data
  } catch (error) {
    logger({ fileName: 'companyPartner', error: error })
    console.log(error)
    return {}
  }
}
