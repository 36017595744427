import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import { isMobile } from '../../helpers/index'
import TimeCounter from './TimeCounter'

const isMobileBrowser = isMobile()

const UpcomingActions = ({ viewBooking, actions }) => {
  if (isMobileBrowser) {
    return (
      <>
        {actions.map(item => (
          <div className='grey-background'>
            <Row className='d-flex align-items-center justify-content-between mt-1'>
              <Col className='d-flex flex-column col-auto pt-1 pr-0 '>
                <span className='partner-action-title'>{item.number}</span>
                <span className='partner-action-message '>{item.message}</span>
                <TimeCounter
                  colClassName={'px-0'}
                  className={'partner-action-counter'}
                  date={item.date}
                  message={item.message}
                  timeZoneId={item.timeZoneId}
                />
              </Col>
              <Col className=' col-auto d-flex justify-content-end pt-1 pl-0'>
                <Button
                  className='partner-btn-desktop-primary select-vehicle'
                  variant='brown'
                  onClick={() => {
                    viewBooking(item.bookingId)
                  }}
                >
                  View
                </Button>
              </Col>
            </Row>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      {actions.map(item => (
        <div className='grey-background'>
          <Row className='d-flex align-items-center justify-content-between mt-1'>
            <Col className='d-flex flex-column col-auto pt-1 pr-0 '>
              <span className='partner-action-title'>{item.number}</span>
              <span className='partner-action-message'>{item.message}</span>
            </Col>
            <Col className=' col-auto d-flex justify-content-end pt-1 pl-0'>
              {
                <TimeCounter
                  colClassName={'d-flex align-items-center'}
                  className={'partner-action-counter'}
                  date={item.date}
                  message={item.message}
                  timeZoneId={item.timeZoneId}
                />
              }
              <Button
                className='partner-btn-desktop-primary select-vehicle'
                variant='brown'
                onClick={() => {
                  viewBooking(item.bookingId)
                }}
              >
                View
              </Button>
            </Col>
          </Row>
        </div>
      ))}
    </>
  )
}

export default withRouter(connect(null, {})(UpcomingActions))
