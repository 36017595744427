import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connectedApiService } from '..'
import moment from 'moment'
import { logger } from '../actions'
const initialData = { list: [], meta: {} }

const defaultPagination = {
  page: 1,
}

const defaultFilters = {}
const defaultOptions = {
  limit: 10,
  filterOperator: 'like',
  onlyFilter: false,
  isFetchDataPerPage: false,
}

export const useGetTableData = (
  url = '',
  initialFilters = defaultFilters,
  {
    limit = 10,
    filterOperators = {},
    filterOperator = 'like',
    onlyFilter = false,
    useLS = false,
    logicService = '',
    isFetchDataPerPage = false,
    isAutomationTable = false,
  } = defaultOptions
) => {
  const [isLoading, setIsLoading] = useState(true)
  const [filters, setFilters] = useState({ ...initialFilters })
  const [pagination, setPagination] = useState({ page: 1 })
  const [filterKey, setFilterKey] = useState({
    targetName: null,
    targetValue: null,
  })
  const getListTimeout = useRef(null)
  const data = useRef({ ...initialData })
  const cachedUrl = useRef(null)
  const [needReload, setNeedReload] = useState(false)

  const getNextPage = page => {
    if (!isLoading && data.current.list) {
      const nextPage = page ? page : pagination.page + 1
      if (
        !isLoading &&
        data.current.meta.totalItems > data.current.list.length
      ) {
        setPagination({
          ...pagination,
          page: nextPage,
        })
      }
    }
  }

  const onFiltersChange = useCallback(
    key => e => {
      if (typeof e === 'string') {
        setFilters({
          ...filters,
          [key]: e || '',
        })
        setPagination({ ...pagination, page: 1 })
        return
      }
      let val = e.target.value || ''
      if (val && e.target.isDate) {
        const time = e.target.isStart ? '00:00:00' : '23:59:59'
        val = moment.utc(`${val} ${time}`, 'DD/MM/YYYY HH:mm:ss').toISOString()
      }

      setFilters({
        ...filters,
        [key]: val || '',
      })
      setFilterKey({
        targetName: e.target.name,
        targetValue: e.target.value,
      })
      setPagination({ ...pagination, page: 1 })
    },
    [setFilters, setFilterKey, setPagination, filters, pagination]
  )

  const onFiltersChangeSelect = useCallback(
    (key, isSearchBooking = false) => e => {
      //isSearchBooking is never true
      if (typeof e.value === 'string') {
        if (key === 'partnerMode') {
          if (e.value === 'chauffeur') {
            setFilters({
              ...filters,
              selectChauffeur: 'true',
              selectRental: '',
              vipMeetAndGreet: '',
            })
          } else if (e.value === 'carRental') {
            setFilters({
              ...filters,
              selectRental: 'true',
              selectChauffeur: '',
              vipMeetAndGreet: '',
            })
          } else if (e.value === 'vipMeetAndGreet') {
            setFilters({
              ...filters,
              vipMeetAndGreet: 'true',
              selectRental: '',
              selectChauffeur: '',
            })
          } else {
            setFilters({
              ...filters,
              selectRental: '',
              selectChauffeur: '',
              vipMeetAndGreet: '',
            })
          }
        } else {
          //booking search ( this is for type/meetGreet )
          if (e.value === 'isMeetGreet') {
            delete filters['type']
            setFilters({
              ...filters,
              isMeetGreet: true,
            })
          } else {
            delete filters['isMeetGreet']
            setFilters({
              ...filters,
              [key]: e.value || '',
            })
          }
        }
        // }
        setPagination({ ...pagination, page: 1 })
        return
      } else if (typeof e.value === 'boolean') {
      }
    },
    [setFilters, setPagination, filters, pagination]
  )

  const getList = useCallback(async url => {
    const { getTableData, getTableDataLS } = connectedApiService
    try {
      setIsLoading(true)
      const { data: list, meta = {} } = useLS
        ? await getTableDataLS(url, logicService)
        : await getTableData(url)
      if (list && list.length > 0) {
        const dataList = list.filter(item => item !== null)
        if (cachedUrl.current === url) {
          const oldList = data.current.list
          const newList = isFetchDataPerPage
            ? dataList
            : meta.page > 1
            ? [...oldList, ...dataList]
            : dataList
          data.current = { list: newList, meta } || { ...initialData }
        }
      } else {
        if (cachedUrl.current === url) {
          const oldList = data.current.list
          const newList = meta.page > 1 ? [...oldList, ...list] : list

          data.current = { list: newList, meta } || { ...initialData }
        }
      }

      setIsLoading(false)
    } catch (error) {
      logger({ fileName: 'useGetTableData', error: error })
      console.log(error)
      data.current = { ...initialData }
      setIsLoading(false)
    }
  }, [])

  const reloadList = () => {
    setPagination({
      ...pagination,
      page: isAutomationTable ? pagination.page : 1,
    })
    setNeedReload(true)
  }

  const hasFilters = useMemo(() => Object.values(filters).join('').length, [
    filters,
  ])

  const clearGetListTimeout = useCallback(() => {
    if (getListTimeout.current) {
      clearTimeout(getListTimeout.current)

      getListTimeout.current = null
    }
  }, [])

  const getQueryParams = useCallback(() => {
    const _pagination = {
      ...defaultPagination,
      ...pagination,
      limit: limit || 10,
    }
    let queryParams = Object.keys(_pagination || {}).map(
      param => `${param}=${_pagination[param]}`
    )
    if (filters) {
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          let operator =
            filterOperators && filterOperators[key]
              ? filterOperators[key]
              : filterOperator
          if (key === 'pickUpDate' || key === 'dropOffDate') {
            queryParams.push(`filter[pickUpDate,${operator}]=${filters[key]}`)
          } else if (key === 'bookingDate' || key === 'createdAt') {
            const startOf = moment
              .utc(filters[key])
              .startOf('day')
              .toISOString()

            const endOf = moment
              .utc(filters[key])
              .endOf('day')
              .toISOString()
            queryParams.push(`filter[createdAt,gte]=${startOf}`)
            queryParams.push(`filter[createdAt,lte]=${endOf}`)
          } else if (key === 'rateFrom' || key === 'rateTo') {
            if (key === 'rateFrom') {
              queryParams.push(`filter[rate,${operator}]=${filters[key] - 1}`)
            }
            if (key === 'rateTo') {
              queryParams.push(`filter[rate,${operator}]=${filters[key] - 1}`)
            }
          } else if (key === 'fromDueDate' || key === 'toDueDate') {
            queryParams.push(`filter[dueDate,${operator}]=${filters[key]}`)
          } else if (key === 'createdAtFrom' || key === 'createdAtTo') {
            const startOf = moment
              .utc(filters[key])
              .startOf('day')
              .toISOString()
            const endOf = moment
              .utc(filters[key])
              .endOf('day')
              .toISOString()
            if (key === 'createdAtFrom') {
              queryParams.push(`filter[createdAt,${operator}]=${startOf}`)
            }
            if (key === 'createdAtTo') {
              queryParams.push(`filter[createdAt,${operator}]=${endOf}`)
            }
          } else {
            if (
              filterKey.targetName === 'clients-name-search' ||
              filterKey.targetName === 'partner-name-search'
            ) {
              if (filters['name'].search('&') !== -1 && key === 'name') {
                queryParams.push(
                  `filter[${key},${operator}]=${filters['name']
                    .split('&')
                    .join('.-.and.-.')}`
                )
                // val = newValue
              } else {
                queryParams.push(`filter[${key},${operator}]=${filters[key]}`)
              }
            } else {
              queryParams.push(`filter[${key},${operator}]=${filters[key]}`)
            }
          }
        }
      })
    }

    return queryParams
  }, [pagination, filters, filterOperator, filterOperators, limit])

  useEffect(() => {
    if (url && (!onlyFilter || (onlyFilter && hasFilters))) {
      const queryParams = getQueryParams()
      const fullUrl = `${url}?${queryParams.join('&')}`
      if (cachedUrl.current !== fullUrl || needReload) {
        cachedUrl.current = fullUrl
        setNeedReload(false)

        if (!filters || !hasFilters) {
          getList(fullUrl)
        } else {
          clearGetListTimeout(getListTimeout)
          getListTimeout.current = setTimeout(() => getList(fullUrl), 1500)
        }
      }
    } else {
      cachedUrl.current = null
      data.current = { ...initialData }
    }
  }, [
    url,
    filters,
    getList,
    onlyFilter,
    getQueryParams,
    clearGetListTimeout,
    hasFilters,
    needReload,
  ])
  return {
    data: data.current,
    isLoading,
    pagination,
    filters,
    filterKey,
    getNextPage,
    onFiltersChange,
    onFiltersChangeSelect,
    reloadList,
    setFilters,
    setPagination,
  }
}
