import React, { useEffect, useState } from 'react'
import { Button, Image, Modal } from 'react-bootstrap'
import { isMobile } from 'helpers'

const isMobileBrowser = isMobile()

const NewUpdateModal = ({ show, onHide, values }) => {
  const onClose = () => {
    onHide()
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <Modal
        size={isMobileBrowser ? 'md' : 'lg'}
        show={show}
        onHide={onClose}
        backdrop='static'
        className='whats-new-modal'
        dialogClassName='modal-size'
        backdropClassName='whats-new-backdrop'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          <div className='modal-panel-update'>
            <div
              className='modal-container'
              style={isMobileBrowser ? { paddingBottom: '45px' } : {}}
            >
              <Modal.Header></Modal.Header>
              <p className='title' style={{ textAlign: 'center' }}>
                {values.title}
              </p>
              <p className='description' style={{ textAlign: 'center' }}>
                {values.description} {values.os ? values.os.store : 'App Store'}
                .
              </p>
              {values.os && (
                <img
                  src={values.os.image}
                  className='update-img'
                  onClick={() => window.open(values.os.link, '_blank')}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default NewUpdateModal
