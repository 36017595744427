import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { isMobile } from 'helpers'
import { connectedApiService as api } from 'index'
import { getUserCompanyInfo } from 'actions'
import { useDispatch } from 'react-redux'

const isMobileBrowser = isMobile()

const TermsOfService = ({ company }) => {
  const [showPartnerTerms, setShowPartnerTerms] = useState(false)
  const [showReview, setShowReview] = useState(true)
  const [isAccepted, setIsAccepted] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [selectedTerms, setSelectedTerms] = useState('')
  const [partner, setPartner] = useState({ name: '', address: '' })

  const termsDivRef = useRef(null)
  const dispatch = useDispatch()

  const terms = {
    ROW: {
      legalEntity: 'ROLZO S.à r.l',
      agreement: `ROLZO S.à r.l, a private limited liability company incorporated and validly existing under Luxembourg law, having its registered office at 68 rue de Strasbourg, 2560 Luxembourg, Grand Duchy of Luxembourg, registered with the Luxembourg Trade and Companies Register under number B286600, ("ROLZO" and together with Partner, the “Parties,” and each, a “Party”). This Agreement includes and incorporates Services Order, as well as the ROLZO Terms located at https://rolzo.com/en/partners-terms/row. There shall be no force or effect to any different terms of any related service order or similar form, even if signed by the parties after the date hereof.`,
    },
    USA: {
      legalEntity: 'ROLZO, Inc.',
      agreement: `ROLZO, Inc. located at 2035 Sunset Lake Rd Suite B-2, Newark, DE 19702, USA, ("ROLZO" and together with Partner, the “Parties,” and each, a “Party”). This Agreement includes and incorporates Services Order, as well as the ROLZO Terms located at https://rolzo.com/en/partners-terms/americas. There shall be no force or effect to any different terms of any related service order or similar form, even if signed by the parties after the date hereof.`,
    },
    UK: {
      legalEntity: 'ROLZO S.à r.l',
      agreement: `ROLZO S.à r.l, a private limited liability company incorporated and validly existing under Luxembourg law, having its registered office at 68 rue de Strasbourg, 2560 Luxembourg, Grand Duchy of Luxembourg, registered with the Luxembourg Trade and Companies Register under number B286600, ("ROLZO" and together with Partner, the “Parties,” and each, a “Party”). This Agreement includes and incorporates Services Order, as well as the ROLZO Terms located at https://rolzo.com/en/partners-terms/uk. There shall be no force or effect to any different terms of any related service order or similar form, even if signed by the parties after the date hereof.`,
    },
  }

  useEffect(() => {
    if (company && company.type === 'supplier') {
      if (!company.termsAcceptedAt) {
        setShowPartnerTerms(true)

        setSelectedTerms(
          company.place.country === 'GB'
            ? terms['UK']
            : company.place.country === 'US'
            ? terms['USA']
            : terms['ROW']
        )
        setPartner({
          _id: company.id,
          name: company.name,
          address: company.address,
        })
      }
    }
  }, [])

  const handleScroll = event => {
    const { scrollTop, scrollHeight, clientHeight } = event.target

    if (scrollTop + clientHeight >= scrollHeight - 1) {
      setIsAccepted(true)
    } else {
      setIsAccepted(false)
    }

    setIsScrolling(scrollTop > 0)
  }

  return (
    <>
      {showPartnerTerms ? (
        <>
          {!isMobileBrowser ? (
            <div style={{ overflow: 'hidden' }}>
              <Modal
                size={'lg'}
                show={true}
                backdrop='static'
                className='terms-modal'
                dialogClassName='modal-size'
                backdropClassName='whats-new-backdrop'
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Body>
                  <div className='modal-panel'>
                    <div className='modal-container'>
                      <div
                        style={{
                          borderBottom: isScrolling
                            ? '1.5px solid rgba(139,149,158,.2)'
                            : 'none',
                        }}
                      >
                        <p className='title'>Partner's Terms of Service</p>
                        <p className='description'>
                          Please review and accept the Partner's Terms of
                          Service, which take effect on the 1st of January 2025.
                          Approval is required before accepting further
                          bookings.
                        </p>
                      </div>
                      <div
                        style={{
                          marginRight: '40px',
                        }}
                      >
                        <div
                          className='terms'
                          ref={termsDivRef}
                          onScroll={handleScroll}
                        >
                          <p className='text-normal'>
                            This Agreement is effective as of Wed, 1st Jan 2025,
                            between {partner.name} incorporated at{' '}
                            {partner.address} ("Partner") and{' '}
                            {selectedTerms.agreement}
                          </p>
                          <p className='text-bold'>EXHIBIT A</p>
                          <p className='text-bold'>FORM OF SERVICE SCHEDULE</p>
                          <p className='text-normal'>
                            This Schedule is made under the Master Service
                            Agreement for Services defined below, dated Wed, 1st
                            Jan 2025 (the "Agreement"), between {partner.name}{' '}
                            ("Partner") and {selectedTerms.legalEntity}{' '}
                            ("ROLZO"). This Service Schedule becomes effective
                            as of that same date.
                          </p>
                          <p className='text-bold'>SERVICES/TASKS</p>
                          <p className='text-normal'>
                            The Partner will provide Passenger Transport
                            Services to ROLZO.
                          </p>
                          <p className='text-bold'>
                            VEHICLE AND CHAUFFEUR COMPLIANCE
                          </p>
                          <p className='text-normal'>
                            All vehicles must undergo regular servicing and
                            scheduled maintenance in accordance with the
                            manufacturer's recommendations and be properly
                            licensed, ensuring full compliance with local road
                            traffic regulations. Similarly, all chauffeurs must
                            maintain valid licenses and certifications and
                            adhere to all local traffic laws and regulations,
                            ensuring the highest standards of safety and
                            professionalism.
                          </p>
                          <p className='text-bold'>VEHICLE ALLOCATION</p>
                          <p className='text-normal'>
                            For all bookings, the Partner will provide the
                            specific make and model requested by ROLZO.
                          </p>
                          <p className='text-bold'>CHAUFFEUR ASSIGNMENT</p>
                          <p className='text-normal'>
                            For all bookings, the Partner will provide ROLZO
                            with chauffeur details, including the chauffeur's
                            name, phone number, vehicle model, and plate number,
                            12 to 24 hours before the service.
                          </p>
                          <p className='text-bold'>AMENITIES</p>
                          <p className='text-normal'>
                            For all bookings, the Partner will ensure the
                            following amenities are available on board: one
                            water bottle per passenger, tissues, and mints,
                            neatly placed in a visible compartment within the
                            vehicle. If not immediately visible, the chauffeur
                            should present and offer these amenities to the
                            passengers verbally.
                          </p>
                          <p className='text-bold'>WAITING TIME</p>
                          <p className='text-normal'>
                            For all bookings that include a period of waiting
                            time, the Partner will provide the following
                            complimentary waiting time: Commercial flights: 60
                            minutes of complimentary waiting time from the
                            flight's landing time; Private flights: 30 minutes
                            of complimentary waiting time from the flight's
                            landing time; Cruise ships: 30 minutes of
                            complimentary waiting time from the cruise ship's
                            arrival time; All other journeys: 15 minutes of
                            complimentary waiting time.
                          </p>
                          <p className='text-bold'>CANCELLATION POLICY</p>
                          <p className='text-normal'>
                            If ROLZO cancels an accepted booking before the
                            booking commences, the Partner shall receive a
                            payment if applicable. For transfer services lasting
                            less than 2 hours, (a) No payment due if cancelled
                            more than 4 hours before commencement of the
                            booking; (b) 100% of the booking price if cancelled
                            less than 4 hours before commencement of the
                            booking. For transfer services lasting more than 2
                            hours, (a) No payment due if cancelled more than 24
                            hours before commencement of the booking; (b) 100%
                            of the booking price if cancelled less than 24 hours
                            before commencement of the booking. For hourly
                            services, (a) No payment due if cancelled more than
                            24 hours before commencement of the booking; (b)
                            100% of the booking price if cancelled less than 24
                            hours before commencement of the booking. If the
                            cancellation policy specified on the booking differs
                            from this general policy, the booking-specific
                            policy will supersede this general policy.
                          </p>
                          <p className='text-bold'>TAXES, TOLLS, FEES</p>
                          <p className='text-normal'>
                            ROLZO offers include all applicable taxes, tolls,
                            and additional fees, such as airport surcharges and
                            parking charges.
                          </p>
                          <p className='text-bold'>SURGE PRICING</p>
                          <p className='text-normal'>
                            The Partner will give ROLZO written notice at least
                            three months before implementing any upcoming surge
                            pricing related to local events or other factors.
                          </p>
                          <p className='text-bold'>PAYMENT AND INVOICING</p>
                          <p className='text-normal'>
                            ROLZO will issue a monthly statement to the Partner,
                            listing all services completed by the Partner during
                            the month. ROLZO will generate a self-billed Invoice
                            on behalf of the Partner for the services rendered.
                            This invoice will be sent to the Partner via email
                            and accessible through the Partner's account on the
                            ROLZO platform on the 5th of each month.{' '}
                          </p>
                          <p className='text-normal'>
                            Payment will be made to the Partner's designated
                            bank account after 30 days. If the Partner
                            identifies any discrepancies, they must notify ROLZO
                            within 7 days of issuing the invoice. After this
                            window, no further adjustments will be possible.{' '}
                          </p>
                          <p className='text-normal'>
                            The Partner irrevocably authorises and instructs
                            ROLZO, acting as its agent, to generate an invoice
                            for payment due based on the services the Partner
                            has successfully completed and recorded on the ROLZO
                            platform.
                          </p>
                          <p className='text-bold'>COMPLAINTS</p>
                          <p className='text-normal'>
                            If ROLZO receives complaints regarding services
                            provided by the Partner, the Partner commits to
                            promptly investigate each case and reach an amicable
                            resolution within 24 hours. Depending on the outcome
                            of the resolution, ROLZO retains the discretion to
                            issue a refund to the client withhold payment to the
                            Partner.
                          </p>
                        </div>
                      </div>
                      <div
                        className='terms-button'
                        style={{
                          borderTop: !isAccepted
                            ? '1.5px solid rgba(139,149,158,.2)'
                            : 'none',
                        }}
                      >
                        <Button
                          className='btn'
                          variant='brown'
                          onClick={async () => {
                            setShowPartnerTerms(false)
                            await api.patch(`showTerms/partner/${partner._id}`)

                            await dispatch(getUserCompanyInfo())
                          }}
                          disabled={!isAccepted}
                        >
                          I accept
                        </Button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          ) : (
            <>
              {showReview ? (
                <div className='terms-modal-mobile'>
                  <div className='modal-container'>
                    <div className='content-container-terms'>
                      <div className='popup-content'>
                        <h2 className='popup-title'>Partner's</h2>
                        <h2 className='popup-title'>Terms of Service</h2>
                        <p className='popup-text'>
                          Please review and accept the Partner's Terms of
                          Service, which take effect on the 1st of January 2025.
                          Approval is required before accepting further
                          bookings.
                        </p>
                      </div>

                      <div className='bottom-container'>
                        <div
                          style={{
                            display: 'flex',
                            marginBottom: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            className='btn'
                            variant='brown'
                            onClick={async () => {
                              setShowReview(false)
                            }}
                            style={{ width: '90%' }}
                          >
                            Review now
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='terms-modal-mobile'>
                  <div className='modal-container'>
                    <div className='content-container-terms'>
                      <div
                        className='driver-booking-container-header-booking'
                        style={{
                          position: 'relative',
                          borderBottom: isScrolling
                            ? '1.5px solid rgba(139,149,158,.2)'
                            : 'none',
                          padding: '16px',
                        }}
                      >
                        <img
                          className='driver-back-icon mx-50%'
                          src='/images/icon-back.png'
                          onClick={() => {
                            setShowReview(true)
                          }}
                          style={{ width: '18px', height: '15px', right: 0 }}
                        />
                        <span></span>
                        <span></span>
                      </div>
                      <div
                        className='terms'
                        ref={termsDivRef}
                        onScroll={handleScroll}
                      >
                        <p className='text-normal'>
                          This Agreement is effective as of Wed, 1st Jan 2025,
                          between {partner.name} incorporated at{' '}
                          {partner.address} ("Partner") and{' '}
                          {selectedTerms.agreement}
                        </p>
                        <p className='text-bold'>EXHIBIT A</p>
                        <p className='text-bold'>FORM OF SERVICE SCHEDULE</p>
                        <p className='text-normal'>
                          This Schedule is made under the Master Service
                          Agreement for Services defined below, dated Wed, 1st
                          Jan 2025 (the "Agreement"), between {partner.name}{' '}
                          ("Partner") and {selectedTerms.legalEntity} ("ROLZO").
                          This Service Schedule becomes effective as of that
                          same date.
                        </p>
                        <p className='text-bold'>SERVICES/TASKS</p>
                        <p className='text-normal'>
                          The Partner will provide Passenger Transport Services
                          to ROLZO.
                        </p>
                        <p className='text-bold'>
                          VEHICLE AND CHAUFFEUR COMPLIANCE
                        </p>
                        <p className='text-normal'>
                          All vehicles must undergo regular servicing and
                          scheduled maintenance in accordance with the
                          manufacturer's recommendations and be properly
                          licensed, ensuring full compliance with local road
                          traffic regulations. Similarly, all chauffeurs must
                          maintain valid licenses and certifications and adhere
                          to all local traffic laws and regulations, ensuring
                          the highest standards of safety and professionalism.
                        </p>
                        <p className='text-bold'>VEHICLE ALLOCATION</p>
                        <p className='text-normal'>
                          For all bookings, the Partner will provide the
                          specific make and model requested by ROLZO.
                        </p>
                        <p className='text-bold'>CHAUFFEUR ASSIGNMENT</p>
                        <p className='text-normal'>
                          For all bookings, the Partner will provide ROLZO with
                          chauffeur details, including the chauffeur's name,
                          phone number, vehicle model, and plate number, 12 to
                          24 hours before the service.
                        </p>
                        <p className='text-bold'>AMENITIES</p>
                        <p className='text-normal'>
                          For all bookings, the Partner will ensure the
                          following amenities are available on board: one water
                          bottle per passenger, tissues, and mints, neatly
                          placed in a visible compartment within the vehicle. If
                          not immediately visible, the chauffeur should present
                          and offer these amenities to the passengers verbally.
                        </p>
                        <p className='text-bold'>WAITING TIME</p>
                        <p className='text-normal'>
                          For all bookings that include a period of waiting
                          time, the Partner will provide the following
                          complimentary waiting time: Commercial flights: 60
                          minutes of complimentary waiting time from the
                          flight's landing time; Private flights: 30 minutes of
                          complimentary waiting time from the flight's landing
                          time; Cruise ships: 30 minutes of complimentary
                          waiting time from the cruise ship's arrival time; All
                          other journeys: 15 minutes of complimentary waiting
                          time.
                        </p>
                        <p className='text-bold'>CANCELLATION POLICY</p>
                        <p className='text-normal'>
                          If ROLZO cancels an accepted booking before the
                          booking commences, the Partner shall receive a payment
                          if applicable. For transfer services lasting less than
                          2 hours, (a) No payment due if cancelled more than 4
                          hours before commencement of the booking; (b) 100% of
                          the booking price if cancelled less than 4 hours
                          before commencement of the booking. For transfer
                          services lasting more than 2 hours, (a) No payment due
                          if cancelled more than 24 hours before commencement of
                          the booking; (b) 100% of the booking price if
                          cancelled less than 24 hours before commencement of
                          the booking. For hourly services, (a) No payment due
                          if cancelled more than 24 hours before commencement of
                          the booking; (b) 100% of the booking price if
                          cancelled less than 24 hours before commencement of
                          the booking. If the cancellation policy specified on
                          the booking differs from this general policy, the
                          booking-specific policy will supersede this general
                          policy.
                        </p>
                        <p className='text-bold'>TAXES, TOLLS, FEES</p>
                        <p className='text-normal'>
                          ROLZO offers include all applicable taxes, tolls, and
                          additional fees, such as airport surcharges and
                          parking charges.
                        </p>
                        <p className='text-bold'>SURGE PRICING</p>
                        <p className='text-normal'>
                          The Partner will give ROLZO written notice at least
                          three months before implementing any upcoming surge
                          pricing related to local events or other factors.
                        </p>
                        <p className='text-bold'>PAYMENT AND INVOICING</p>
                        <p className='text-normal'>
                          ROLZO will issue a monthly statement to the Partner,
                          listing all services completed by the Partner during
                          the month. ROLZO will generate a self-billed Invoice
                          on behalf of the Partner for the services rendered.
                          This invoice will be sent to the Partner via email and
                          accessible through the Partner's account on the ROLZO
                          platform on the 5th of each month.{' '}
                        </p>
                        <p className='text-normal'>
                          Payment will be made to the Partner's designated bank
                          account after 30 days. If the Partner identifies any
                          discrepancies, they must notify ROLZO within 7 days of
                          issuing the invoice. After this window, no further
                          adjustments will be possible.{' '}
                        </p>
                        <p className='text-normal'>
                          The Partner irrevocably authorises and instructs
                          ROLZO, acting as its agent, to generate an invoice for
                          payment due based on the services the Partner has
                          successfully completed and recorded on the ROLZO
                          platform.
                        </p>
                        <p className='text-bold'>COMPLAINTS</p>
                        <p className='text-normal'>
                          If ROLZO receives complaints regarding services
                          provided by the Partner, the Partner commits to
                          promptly investigate each case and reach an amicable
                          resolution within 24 hours. Depending on the outcome
                          of the resolution, ROLZO retains the discretion to
                          issue a refund to the client withhold payment to the
                          Partner.
                        </p>
                      </div>

                      <div className='bottom-container'>
                        <div
                          style={{
                            display: 'flex',
                            marginBottom: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            className='btn'
                            variant='brown'
                            onClick={async () => {
                              setShowPartnerTerms(false)
                              await api.patch(
                                `showTerms/partner/${partner._id}`
                              )

                              await dispatch(getUserCompanyInfo())
                            }}
                            style={{ width: '90%' }}
                            disabled={!isAccepted}
                          >
                            I accept
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default TermsOfService
