import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useImperativeHandle,
} from 'react'
import { connect } from 'react-redux'
import { connectedApiService as api } from '../../index'
import { Button, Nav, Col, Row } from 'react-bootstrap'
import {
  withRouter,
  useLocation,
  NavLink,
  useParams,
  Link,
} from 'react-router-dom'
import TabsNav from '../../components/TabsNav'
import LoadingSpinner from '../../components/LoadingSpinner'
import { SmallUp, SmallDown } from '../../components/Responsive/breakpoints'
import DriverBookingCard from '../../components/DriverBookingCard'
import DriverBookingTable from 'components/DriverBookingTable'
import AddCard from '../DriversApp/AddCard'
import InfoCard from '../DriversApp/InfoCard'
import {
  isAndroid,
  isIos,
  isMobile,
  numberWithCommas,
  formatSentenceCase,
} from '../../helpers'
import _ from 'lodash'
import {
  getPartnerDetails,
  logout,
  markPopUpAsSeenPartner,
  setMessageClose,
  setMessageOpen,
  updateEarningInfo,
  updatePartnerBanner,
} from 'actions'
import FormFieldWithIcon from '../../components/FormFieldWithIcon'
import FormInputDatepicker from '../../components/FormInputDatepicker'
import moment from 'moment'
import invoke from 'react-native-webview-invoke/browser'
import {
  defaultBookingStatusPartner,
  defaultChauffeurStatusPartner,
  defaultDispatchStatusPartner,
  partnerHostLive,
  partnerHostStaging,
} from '../../constants'
import FormSelect from 'components/FormSelect'
import Earnings from '../../components/Payouts/Earnings'
import PartnerPayout from '../../components/Payouts/PartnerPayout'
import Commissions from 'pages/App/CompanyPages/Payments/Commissions'
import useBottomScrollListener from 'hooks/useBottomScrollListener'
import WhatsNewModal from 'components/WhatsNewModal'
import FilterButton from 'components/FilterButton'
import TermsOfService from 'components/TermsOfService'
import NewUpdateModal from 'components/NewUpdateModal'
import { ComPartnerUpdateModal } from 'components/ComPartnerUpdateModal'
import SuccessMsg from 'components/SuccessMsg'
import UpcomingActions from 'components/Partners/UpcomingActions'
const Host = window.location.hostname

const isMobileBrowser = isMobile()
const userStyles = {
  opacity: '1',
  transition: 'opacity 0.28s',
  paddingBottom: '10px',
  fontSize: '14px',
  marginTop: '4px',
}
const DefaultDriverLayout = ({
  history,
  match,
  data = null,
  partnerVehicles = null,
  partnerChauffeurs = null,
  settingData = null,
  reloadList,
  isAccountLoading = false,
  isSettingsLoading = false,
  logout,
  filters = {},
  isPlannedTab = false,
  isNewBookingTab = false,
  isCompletedTab = false,
  isBookingsLoading = false,
  filtersApplied = false,
  isPartner,
  isDriver,
  isCarRental,
  isChauffeur,
  onFiltersChange = () => {},
  onFiltersChangeSelect = () => {},
  onDateChange = () => {},
  onClearFilters = () => {},
  getPartnerDetails,
  userName,
  isPartnerPayouts = false,
  isPartnerEarnings = false,
  partnerToken,
  currentPartnerType,
  togglePartnerType = () => {},
  partnerName,
  isLocalSupplier,
  whatsNewPartner,
  showSwitchBanner,
  markPopUpAsSeenPartner,
  updatePartnerBanner,
  onScrollBottom = () => {},
  companyId,
  company,
  triggerNotification,
  user,
  updateEarningInfo,
  showEarningIndicator,
  setMessageOpen,
  setMessageClose,
}) => {
  const apiHost = process.env.REACT_APP_APIHOST
  const { token } = useParams()

  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : 'chauffeur'

  function isWebView() {
    const userAgent = navigator.userAgent || ''
    const isAndroidWebView =
      /wv/.test(userAgent) ||
      (/Version\/\d+\.\d+/.test(userAgent) && !/Chrome/.test(userAgent))

    const isIOSWebView =
      !!window.webkit &&
      !!window.webkit.messageHandlers &&
      !/Safari/.test(userAgent)

    const isMobileBrowser = /Chrome|Safari|Firefox|CriOS|FxiOS/.test(userAgent)

    return (isAndroidWebView || isIOSWebView) && !isMobileBrowser
  }

  const isAndroid = /Android/i.test(navigator.userAgent)
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)

  const getOSInfo = () => {
    if (isIOS) {
      return {
        store: 'App Store',
        image:
          'https://storage.googleapis.com/rolzo-static-bucket/icons/Icon-apple-app-store.png',
        link: 'https://apps.apple.com/ae/app/rolzo-partner/id1612210928',
      }
    } else if (isAndroid) {
      return {
        store: 'Google Play Store',
        image:
          'https://storage.googleapis.com/rolzo-static-bucket/icons/Icon-google-play-store.png',
        link:
          'https://play.google.com/store/apps/details?id=com.rolzo.partner&hl=en_US&gl=US',
      }
    }
    return ''
  }

  //Redirect to mobile app
  const hasNotch = window.visualViewport.height >= 812
  if (
    token === 'null' &&
    (apiHost === 'https://business.rolzo.com/api' ||
      apiHost === 'https://partner.rolzo.com/api')
  ) {
    if (isAndroid()) {
      window.location.replace(
        'https://play.google.com/store/apps/details?id=com.rolzo.partner&hl=en_US&gl=US'
      )
    } else if (isIos()) {
      window.location.replace(
        `https://apps.apple.com/ae/app/rolzo-partner/id1612210928`
      )
    }
  }

  const scrollRef = useRef(null)
  useBottomScrollListener(onScrollBottom, scrollRef, 100, 300)

  const viewBooking = bookingId => {
    history.push(
      `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/details/${token}/${bookingId}`
    )
  }
  let [loadingLocation, setLoadingLocation] = useState(null)
  let [isLoading, setIsLoading] = useState(false)

  const [details, setDetails] = useState(null)
  const [multipleRowsList, setMultipleRowsList] = useState([])
  const [homePageInfo, setHomePageInfo] = useState({})
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [initialList, setInitialList] = useState([])
  let [showBanner, setShowBanner] = useState(
    typeof showSwitchBanner === 'boolean' ? showSwitchBanner : false
  )
  const [showWhatsNew, setShowWhatsNew] = useState(false)
  const [showEarningPopUp, setShowEarningPopUp] = useState(false)
  let location = useLocation()

  let currentLocation = location.pathname

  if (loadingLocation != null) {
    currentLocation = loadingLocation
  }

  let isBookingsSelected =
    currentLocation &&
    (currentLocation.endsWith('/new-bookings') ||
      currentLocation.endsWith('/completed') ||
      currentLocation.endsWith('/planned'))

  let isAccountSelected =
    currentLocation && currentLocation.endsWith('/account')

  let isHomePageSelected =
    currentLocation && currentLocation.endsWith('/home-page')

  const bookingAndPartnerVehicles = () => {
    let vehicleList = [{ label: 'All', value: '' }]
    if (isBookingsSelected) {
      if (initialList) {
        initialList.forEach(booking => {
          if (
            booking.vehicleName &&
            !vehicleList.some(el => el.value === booking.vehicleName)
          ) {
            vehicleList.push({
              label: booking.vehicleName,
              value: booking.vehicleName,
            })
          }
        })
      }
    }
    return vehicleList
  }
  const bookingAndPartnerChauffeurs = () => {
    let chauffeurList = [{ label: 'All', value: '' }]
    if (isBookingsSelected) {
      // add partner chauffeurs
      // if (partnerChauffeurs && Array.isArray(partnerChauffeurs)) {
      //   partnerChauffeurs.forEach(chauffeur => {
      //     if (
      //       chauffeur.chauffeurName &&
      //       !chauffeurList.some(el => el.value === chauffeur.chauffeurName)
      //     ) {
      //       chauffeurList.push({
      //         label: formatSentenceCase(chauffeur.chauffeurName.split(' ')[0]),
      //         value: formatSentenceCase(chauffeur.chauffeurName.split(' ')[0]),
      //       })
      //     }
      //   })
      // }
      // add booking chauffeurs
      if (initialList) {
        initialList.forEach(booking => {
          if (
            booking.driver &&
            booking.driver.fullName &&
            !chauffeurList.some(el => el.value === booking.driver.fullName)
          ) {
            chauffeurList.push({
              label: booking.driver.fullName,
              value: booking.driver.fullName,
            })
          } else if (
            booking.driverTemp &&
            booking.driverTemp.fullName &&
            !chauffeurList.some(el => el.value === booking.driverTemp.fullName)
          ) {
            chauffeurList.push({
              label: booking.driverTemp.fullName,
              value: booking.driverTemp.fullName,
            })
          }
        })
      }
    }
    return chauffeurList
  }

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        await getPartnerDetails(token)
      } catch (err) {
        console.log(err)
      }
    }
    const checkTokenValidityDetails = async () => {
      try {
        const url =
          partnerType === 'rental'
            ? `external/partnerRentalToken/${token}`
            : `external/partnerToken/${token}`
        const response = await api.get(url)
        setDetails(response.data)
      } catch (err) {
        console.log(err)
      }
    }
    if (isPartner === null || isDriver === null) {
      checkTokenValidity()
    } else {
      checkTokenValidityDetails()
    }
  }, [])

  useEffect(() => {
    const checkFcmAvailable = async () => {
      try {
        const url =
          partnerType === 'rental'
            ? `external/partnerRentalToken/${token}`
            : `external/partnerToken/${token}`
        const response = await api.get(url)
        if (
          !response.data.fcmAvailable &&
          apiHost === 'https://business.rolzo.com/api'
        ) {
          if (isAndroid()) {
            window.location.replace(
              'https://play.google.com/store/apps/details?id=com.rolzo.partner&hl=en_US&gl=US'
            )
          } else if (isIos()) {
            window.location.replace(
              `https://apps.apple.com/ae/app/rolzo-partner/id1612210928`
            )
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (token) {
      checkFcmAvailable()
    }
  }, [token])

  useEffect(() => {
    if (isHomePageSelected && data && data.length > 0) {
      const [item] = data
      setHomePageInfo(item)
    }
  }, [isHomePageSelected, data])
  useEffect(() => {
    if (loadingLocation) {
      history.push(loadingLocation)
    }
  }, [loadingLocation])

  useEffect(() => {
    if (typeof showSwitchBanner === 'boolean') {
      setShowBanner(showSwitchBanner)
    }
  }, [showSwitchBanner])

  useEffect(() => {
    if (data && !isHomePageSelected && isPartner) {
      let length = 2
      let rest = data.length % 2
      let size = Math.floor(data.length / 2)
      let index = 0
      setMultipleRowsList(
        Array.from({ length }, (_, i) =>
          data.slice(index, (index += size + (i < rest)))
        )
      )
    }
  }, [data, isPartner])

  useEffect(() => {
    if (data && isBookingsSelected) {
      // initialList should only contain unfiltered bookings
      if (data && initialList.length < data.length) {
        setInitialList(data)
      }
    }
  }, [data])

  const isTabActive = (title, path, currentPath) => {
    if (currentPath) {
      const isPathActive = path && currentPath.startsWith(path)

      const isBookingsActive =
        ['planned', 'details', 'completed'].some(segment =>
          currentPath.includes(segment)
        ) && title === 'Bookings'

      const isAccountActive =
        ['chauffeur', 'car', 'settings'].some(segment =>
          currentPath.includes(segment)
        ) && title === 'Account'

      return isPathActive || isBookingsActive || isAccountActive
    } else return false
  }

  const MenuLink = ({ path, title, iconActive, iconInactive, isActive }) => {
    return (
      <NavLink
        className={`nav-link ${isActive ? 'active' : ''}`}
        to={path ? path : currentLocation}
      >
        <SmallUp>
          <img
            className={
              path.includes('home-page')
                ? 'nav-link-img-partner-home'
                : 'nav-link-img-partner'
            }
            src={`/images/${isActive ? iconActive : iconInactive}`}
            alt=''
          />{' '}
          {title === 'Account' && showEarningIndicator ? (
            <div className='missing-earning-indicator' />
          ) : (
            <></>
          )}
        </SmallUp>
        <span>
          <div style={{ display: 'flex', alignContent: 'center' }}>{title}</div>
        </span>
      </NavLink>
    )
  }

  const MenuItem = ({
    title,
    path,
    iconInactive,
    iconActive,
    currentPath,
    childAllowedRoutes,
  }) => {
    if (!childAllowedRoutes) {
      return (
        <MenuLink
          key={path}
          title={title}
          iconActive={iconActive}
          iconInactive={iconInactive}
          path={path}
          isActive={isTabActive(title, path, currentPath)}
        />
      )
    }
  }

  const BottomMenuLink = ({ title, icon, isSelected, onClickHandle }) => {
    return (
      <div className='bottom-nav-link' onClick={onClickHandle}>
        <div
          className='bottom-nav-img-wrapper'
          style={
            title === 'Quotes'
              ? { paddingTop: '2.2px' }
              : title === 'Support'
              ? { paddingTop: '1px' }
              : {}
          }
        >
          <img
            className='bottom-nav-link-img'
            src={'/images/' + icon}
            alt=''
            style={
              title === 'Quotes'
                ? { height: '17px' }
                : title === 'Support'
                ? { height: '22px' }
                : {}
            }
          />
        </div>
        <div className={`${isSelected ? 'selected-nav' : ''}`}>{title}</div>
      </div>
    )
  }

  const BootMenuItem = ({
    title,
    path,
    icon,
    currentPath,
    childAllowedRoutes,
    existUnread,
    onClickHandle,
    showAlertBadge = false,
    counts = 0,
    isAdmin,
    isBooking = false,
  }) => {
    let isSelected = isBooking
      ? path.length > 0 &&
        currentPath &&
        path.some(v => currentPath.includes(v))
      : path && currentPath && currentPath.endsWith(path)

    if (!childAllowedRoutes) {
      return (
        <BottomMenuLink
          title={title}
          icon={icon}
          isSelected={isSelected}
          existUnread={existUnread}
          onClickHandle={onClickHandle}
          showAlertBadge={showAlertBadge}
          counts={counts}
          isAdmin={isAdmin}
        />
      )
    }

    return (
      <>
        {childAllowedRoutes
          .filter(({ hideInSidebar }) => !hideInSidebar)
          .map(child => (
            <div key={child.path}>
              <BottomMenuLink
                key={child.path}
                title={child.title}
                icon={isSelected ? child.iconActive : child.iconInactive}
                isSelected={isSelected}
                existUnread={existUnread}
                onClickHandle={onClickHandle}
                isAdmin={isAdmin ? true : false}
              />
            </div>
          ))}
      </>
    )
  }

  function clickMenu(path) {
    setLoadingLocation(path)
  }

  const tabs = [
    {
      title: isPartner
        ? details && details.bookingsLength
          ? `New offers (${details.bookingsLength.numberOfOffers})`
          : 'New offers'
        : details && details.bookingsLength
        ? `New bookings (${details.bookingsLength.numberOfOffers})`
        : 'New bookings',
      path: `/new-bookings`,
      hasAccess: true,
      isPrivate: false,
    },
    {
      title:
        details && details.bookingsLength
          ? `Planned (${details.bookingsLength.numberOfPlanned})`
          : 'Planned',
      path: `/planned`,
      hasAccess: true,
      isPrivate: false,
    },
    {
      title:
        details && details.bookingsLength
          ? `Completed (${details.bookingsLength.numberOfCompleted})`
          : 'Completed',
      path: `/completed`,
      hasAccess: true,
      isPrivate: false,
    },
  ]

  const tabsAccount = isPartner
    ? [
        {
          title: partnerType === 'rental' ? 'Agents' : 'Chauffeurs',
          path: `/chauffeurs`,
          hasAccess: true,
          isPrivate: false,
        },
        {
          title: 'Vehicles',
          path: `/cars`,
          hasAccess: true,
          isPrivate: false,
        },
        // {
        //   title: 'Earnings',
        //   path: `/earnings`,
        //   hasAccess: true,
        //   isPrivate: false,
        // },
        // {
        //   title: 'Payouts',
        //   path: `/payouts`,
        //   hasAccess: true,
        //   isPrivate: false,
        // },
        {
          title: 'Settings',
          path: `/settings`,
          hasAccess: true,
          isPrivate: false,
        },
      ]
    : [
        {
          title: 'Chauffeur',
          path: `/chauffeursOnly`,
          hasAccess: true,
          isPrivate: false,
        },
        {
          title: 'Company',
          path: `/settings`,
          hasAccess: true,
          isPrivate: false,
        },
      ]

  const routes = [
    {
      path: `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/${token}/home-page`,
      title: 'Home',
      iconActive: 'icons/icon-home-black.png',
      iconInactive: 'icons/icon-home-grey.png',
      hideInSidebar: false,
    },
    {
      path: `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/${token}/new-bookings`,
      title: 'Bookings',
      iconActive: 'icons/icon-bookings-black.png',
      iconInactive: 'icons/icon-bookings-grey.png',
      hideInSidebar: false,
    },
    {
      path: `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/${token}/chauffeurs/account`,
      title: 'Account',
      iconActive: 'menu_account_active.png',
      iconInactive: 'menu_account_inactive.png',
      hideInSidebar: false,
    },
  ]
  useEffect(() => {
    if (whatsNewPartner && isPartner) {
      setShowWhatsNew(true)
    }
  }, [whatsNewPartner, isPartner])

  const onHide = async () => {
    setShowWhatsNew(false)
    await markPopUpAsSeenPartner()
  }

  const renderPopUp = () => (
    <WhatsNewModal
      show={showWhatsNew}
      onHide={onHide}
      values={whatsNewPartner}
    />
  )

  const renderTerms = () => <TermsOfService company={company} />

  const onUpdateBilling = async (id, values) => {
    await updateEarningInfo(id, values)
    setMessageOpen({
      title: `Thank you`,
      message: `Your company information has been submitted successfully.`,
    })

    setTimeout(() => {
      setMessageClose()
    }, 3000)
  }
  const renderEarningPopup = () => {
    return (
      <>
        <ComPartnerUpdateModal
          onHide={() => setShowEarningPopUp(false)}
          show={showEarningPopUp}
          companyId={companyId}
          isPartner
          onHandleFunction={onUpdateBilling}
        />
        <SuccessMsg isPayment />
      </>
    )
  }
  const menuMinimized = minimized => {
    if (document.getElementsByClassName('page-wrap').length === 0) {
      return
    }

    if (minimized) {
      document
        .getElementsByClassName('page-wrap')[0]
        .classList.remove('pagewrap-with-menu')
    } else {
      document
        .getElementsByClassName('page-wrap')[0]
        .classList.add('pagewrap-with-menu')
    }
  }

  function onBackClick(e) {
    e.preventDefault()
    window.history.back()
  }

  function onForwardClick(e) {
    e.preventDefault()
    history.goForward()
  }

  const tabsToNav = useMemo(
    () =>
      !tabs
        ? null
        : tabs
            .filter(tab => {
              return !!tab.hasAccess
            })
            .map(tab => ({
              ...tab,
              path: `${
                Host === partnerHostLive || Host === partnerHostStaging
                  ? '/'
                  : '/partner/'
              }driver/app/${token}${tab.path}`,
            })),
    [tabs, match]
  )

  const tabsToNavAccount = useMemo(
    () =>
      !tabsAccount
        ? null
        : tabsAccount
            .filter(tab => {
              return !!tab.hasAccess
            })
            .map(tab => ({
              ...tab,
              path: `${
                Host === partnerHostLive || Host === partnerHostStaging
                  ? '/'
                  : '/partner/'
              }driver/app/${token}${tab.path}/account`,
            })),
    [tabsAccount, match]
  )
  const setLogOutPartner = invoke.bind('logOutPartner')

  const logOutPartner = async () => {
    if (Host === partnerHostLive || Host === partnerHostStaging) {
      logout()
    } else {
      const removePartnerFcmUrl = `external/removePartnerFcm/${token}`
      const response = await api.get(removePartnerFcmUrl)
      const callGeolocation = await setLogOutPartner()
    }
  }

  const onHideBanner = async () => {
    await updatePartnerBanner(token)
    setShowBanner(false)
    await getPartnerDetails(token)
  }

  const onExportBookings = async () => {
    try {
      const filtersClone = { ...filters, companyId: companyId }

      if (filtersClone.pickUpDate && filtersClone.pickUpDate) {
        // Set the dates to proper format
        const dateFilterKeys = [{ from: 'pickUpDate', to: 'dropOffDate' }]
        dateFilterKeys.forEach(pair => {
          const { from, to } = pair

          if (filtersClone[from] && filtersClone[to]) {
            //Add from/to values to filters
            Object.assign(filtersClone, {
              from: moment
                .utc(filtersClone[from])
                .startOf('day')
                .toISOString(),
              to: moment
                .utc(filtersClone[to])
                .endOf('day')
                .toISOString(),
            })

            // Delete the original keys
            delete filtersClone[from]
            delete filtersClone[to]
          }
        })

        const baseUrl =
          'https://staging.rolzo.com/analytics/partners/partnerExport'
        // const baseUrl = 'http://localhost:3600/analytics/partners/partnerExport'
        const url = new URL(baseUrl)
        Object.keys(filtersClone).forEach(key => {
          if (filtersClone[key]) {
            const paramKey = key
            url.searchParams.append(paramKey, filtersClone[key])
          }
        })

        const fullUrl = url.toString()
        const response = await fetch(fullUrl, { method: 'GET' })

        if (response.ok) {
          const blob = await response.blob()
          const downloadUrl = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = downloadUrl
          a.download = 'Report.csv'
          document.body.appendChild(a)
          a.click()
          setTimeout(() => {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(downloadUrl)
          }, 0)
        } else {
          throw new Error('Network response was not ok.')
        }
      } else if (!filtersClone.pickUpDate) {
        triggerNotification('pickUpDate')
        throw new Error('Filters please select a "From" date.')
      } else if (!filtersClone.dropOffDate) {
        triggerNotification('dropOffDate')
        throw new Error('Filters please select a "To" date.')
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <div className={isMobileBrowser ? '' : 'app-content app-content-partner'}>
      <aside
        id='sidebar'
        className={
          isMobileBrowser
            ? 'sidebar-container sidebar-white'
            : 'sidebar-container-partner sidebar-white'
        }
      >
        <div className='sidebar-content'>
          <SmallUp className='partner-sidebar'>
            {partnerName ? (
              <>
                <div className='company-name-dispatch pb-0'>{partnerName}</div>
                <div className='user-name mb-4' style={userStyles}>
                  {user?.profile.firstName} {user?.profile.lastName}
                </div>
              </>
            ) : (
              <>
                <div className='company-name-dispatch pb-0'> COMPANY </div>
                <div className='user-name mb-4' style={userStyles}>
                  {user?.profile.firstName} {user?.profile.lastName}
                </div>
              </>
            )}
            {isPartner ? (
              <Link
                to='/booking/main/new-booking'
                className='switch-client-btn'
                style={{ marginBottom: '2.5rem' }}
              >
                <span>Switch to client</span>
                <img src='/images/icons/switch-gold.png'></img>
              </Link>
            ) : (
              <></>
            )}
            <Nav className='flex-column sidebar-nav'>
              {routes
                .filter(({ hideInSidebar }) => !hideInSidebar)
                .map(({ path, ...rest }, i) => (
                  <MenuItem
                    key={String(i)}
                    path={path}
                    currentPath={location.pathname}
                    {...rest}
                  />
                ))}
              <a class='nav-link' style={{ cursor: 'pointer' }}>
                <div onClick={logout}>
                  <img
                    class='nav-link-img-partner'
                    src='/images/menu_logout.png'
                    alt=''
                  />
                </div>
                <span>
                  <div
                    onClick={logout}
                    style={{ display: 'flex', alignContent: 'center' }}
                  >
                    Log out
                  </div>
                </span>
              </a>
            </Nav>
          </SmallUp>
        </div>

        {!isMobileBrowser && (
          <div className={`partner-client-logo-wrap`}>
            <img src='/images/rolzo_logo_black.png' alt='' />
          </div>
        )}
      </aside>
      {/* page content */}
      {!showFilterModal ? (
        <>
          <div
            className={
              isMobileBrowser
                ? `page-wrap page-wrap-light page-with-bottom-menu ${
                    isHomePageSelected
                      ? isPartner
                        ? 'grey-theme-background-partner'
                        : 'grey-theme-background-driver'
                      : ''
                  }`
                : 'page-wrap-partner page-wrap-light page-with-bottom-menu'
            }
          >
            <div className={`app-page`}>
              {isWebView() && isMobileBrowser ? (
                <NewUpdateModal
                  show={true}
                  values={{
                    title: 'Update Required to Continue',
                    description: `This version of the app is no longer supported. Please update to the latest version to enjoy the newest features, improved performance, and enhanced security. Tap the button below to download the update from the `,
                    os: getOSInfo(),
                  }}
                ></NewUpdateModal>
              ) : (
                <></>
              )}
              <SmallUp>
                {!isAccountSelected && !isBookingsSelected && (
                  <div style={{ backgroundColor: 'rgba(139, 149, 158, 0.1)' }}>
                    <div className='supportbar-gap'>
                      <div
                        className='supportbar-back-btn-wrap'
                        onClick={e => {
                          onBackClick(e)
                        }}
                      >
                        <img
                          className='supportbar-back-img'
                          src='/images/icon-back-grey.png'
                          alt=''
                        />
                        <span className='supportbar-back-text'>Back</span>
                      </div>
                    </div>
                  </div>
                )}
              </SmallUp>
              <div
                ref={scrollRef}
                className={
                  isMobileBrowser
                    ? 'scrollable-page-content light-theme'
                    : details && details.isDriver
                    ? 'scrollable-page-content grey-theme'
                    : 'scrollable-page-content grey-theme'
                }
                style={
                  isMobileBrowser && details && details.isPartner
                    ? { height: 'calc(100vh - 60px) !important' }
                    : {}
                }
              >
                <nav
                  className={
                    isMobileBrowser
                      ? 'navbar-partner navbar-expand navbar-light'
                      : 'navbar navbar-expand navbar-light'
                  }
                >
                  <span
                    className='navbar-brand'
                    style={
                      isMobileBrowser
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }
                        : {}
                    }
                  >
                    <header
                      className={`partner-header-home ${
                        isHomePageSelected && isMobileBrowser
                          ? 'no-background-colour'
                          : ''
                      }`}
                    >
                      <h1
                        className={'partner-page-title'}
                        style={{ fontSize: '2.125rem' }}
                      >
                        {isBookingsSelected
                          ? 'Bookings'
                          : isHomePageSelected
                          ? 'Home'
                          : isAccountSelected
                          ? 'Account'
                          : ''}
                      </h1>
                      {isHomePageSelected && isPartner ? (
                        <Link
                          to='/booking/main/new-booking'
                          className='switch-client-btn mb-4'
                        >
                          <span>Switch to client</span>
                          <img src='/images/icons/switch-gold.png'></img>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </header>
                    {isMobileBrowser && isAccountSelected && (
                      <div
                        onClick={() => logOutPartner()}
                        className='mobile-logout'
                      >
                        <img
                          src='https://business.rolzo.com/images/icons/logout_mobile.png'
                          alt=''
                        />
                      </div>
                    )}
                  </span>
                </nav>
                <main>
                  {isCarRental &&
                    isChauffeur &&
                    currentLocation.endsWith('home-page') && (
                      <div className='partner-tabs'>
                        <div
                          className={`partner-tab-chauffeur ${
                            !currentPartnerType ||
                            currentPartnerType === 'chauffeur'
                              ? 'tab-active'
                              : 'tab-inactive'
                          }`}
                          onClick={() => {
                            togglePartnerType('chauffeur')
                          }}
                        >
                          Chauffeur service
                        </div>
                        <div
                          className={`partner-tab-rental  ${
                            currentPartnerType === 'rental'
                              ? 'tab-active'
                              : 'tab-inactive'
                          }`}
                          onClick={() => {
                            togglePartnerType('rental')
                          }}
                        >
                          Car rental
                        </div>
                      </div>
                    )}
                  {showBanner && isHomePageSelected && isPartner ? (
                    <div className='switch-client-banner'>
                      <div className='close-div'>
                        <img
                          className='close-icon'
                          src='/images/icons/close-icon.png'
                          onClick={() => onHideBanner()}
                        ></img>
                      </div>
                      <div>
                        <p> Grow Your Chauffeur Business Globally</p>
                        <p>
                          Get a 15% discounted affiliate rate and book chauffeur
                          services for your clients in more than 100 countries,
                          750 cities, and 1,000 airports.
                        </p>
                        <Link
                          to='/booking/main/new-booking'
                          className='switch-client-btn'
                        >
                          <span>Switch to client</span>
                          <img src='/images/icons/switch-gold.png'></img>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {isBookingsSelected ? (
                    <>
                      <TabsNav
                        align='start'
                        tabs={tabsToNav}
                        className='light-tabs-partner'
                      />
                      {
                        <>
                          {data ? (
                            <>
                              {isLoading || isBookingsLoading ? (
                                <LoadingSpinner />
                              ) : (
                                <></>
                              )}

                              <SmallUp>
                                {isLocalSupplier ? (
                                  <>
                                    <div className='partner-grid-container'>
                                      <div className=''>
                                        <FormFieldWithIcon
                                          field={{
                                            name: 'booking-number-search',
                                            placeHolder: `${
                                              isPlannedTab || isCompletedTab
                                                ? 'Booking number'
                                                : isPartner
                                                ? 'Offer number'
                                                : 'Booking number'
                                            }`,
                                            value: filters['number'],
                                            onChange: onFiltersChange('number'),
                                          }}
                                          icon='icons/Icon_Search_Inactive.png'
                                          roundFormField={true}
                                        />
                                      </div>
                                      <div className=''>
                                        <FormInputDatepicker
                                          field={{
                                            name: 'date-from-search',
                                            placeholder: 'From',
                                            value: filters['pickUpDate'],
                                            onChange: onDateChange(
                                              'pickUpDate'
                                            ),
                                          }}
                                          isISODate
                                          isSearchField={true}
                                          roundFormField={true}
                                        />
                                      </div>
                                      <div className=''>
                                        <FormInputDatepicker
                                          field={{
                                            name: 'date-to-search',
                                            placeholder: 'To',
                                            value: filters['dropOffDate'],
                                            onChange: onDateChange(
                                              'dropOffDate'
                                            ),
                                          }}
                                          isISODate
                                          isSearchField={true}
                                          roundFormField={true}
                                        />
                                      </div>
                                      {/* <div className=''>
                                          <FormSelect
                                            field={{
                                              name: 'partner-search',
                                            }}
                                            handleChange={onFiltersChangeSelect(
                                              'partnerName'
                                            )}
                                            options={bookingAndPartnerVehicles()}
                                            placeholder='Partner'
                                            roundFormField={true}
                                          />
                                        </div> */}
                                      {isPlannedTab || isCompletedTab ? (
                                        <>
                                          <div className=''>
                                            <FormFieldWithIcon
                                              field={{
                                                name: 'booking-number-search',
                                                placeHolder: `Partner name`,
                                                value: filters['partnerName'],
                                                onChange: onFiltersChange(
                                                  'partnerName'
                                                ),
                                              }}
                                              icon='icons/Icon_Search_Inactive.png'
                                              roundFormField={true}
                                            />
                                          </div>
                                          <div className=''>
                                            <FormSelect
                                              field={{
                                                name: 'booking-search',
                                              }}
                                              handleChange={onFiltersChangeSelect(
                                                'bookingStatus'
                                              )}
                                              options={
                                                isPlannedTab
                                                  ? [
                                                      {
                                                        label: 'All',
                                                        value: '',
                                                      },
                                                      {
                                                        label: 'Accepted',
                                                        value: 'accepted',
                                                      },
                                                      {
                                                        label: 'Declined',
                                                        value: 'declined',
                                                      },

                                                      {
                                                        label: 'Outsourced',
                                                        value: 'outsourced',
                                                      },
                                                      {
                                                        label: 'Cancelled',
                                                        value: 'cancelled',
                                                      },
                                                    ]
                                                  : isCompletedTab
                                                  ? [
                                                      {
                                                        label: 'All',
                                                        value: '',
                                                      },
                                                      {
                                                        label: 'Accepted',
                                                        value: 'accepted',
                                                      },
                                                      {
                                                        label: 'Cancelled',
                                                        value: 'cancelled',
                                                      },
                                                    ]
                                                  : [
                                                      {
                                                        label: 'All',
                                                        value: '',
                                                      },
                                                      {
                                                        label: 'Pending',
                                                        value: 'in_review',
                                                      },
                                                    ]
                                              }
                                              placeholder='Booking status'
                                              roundFormField={true}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      <div className=''>
                                        <FormSelect
                                          field={{
                                            name: 'vehicle-search',
                                          }}
                                          handleChange={onFiltersChangeSelect(
                                            'vehicleName'
                                          )}
                                          options={bookingAndPartnerVehicles()}
                                          placeholder='Vehicle'
                                          roundFormField={true}
                                        />
                                      </div>
                                      {isPlannedTab || isCompletedTab ? (
                                        <>
                                          <div className=''>
                                            <FormSelect
                                              field={{
                                                name: 'chauffeur-search',
                                              }}
                                              handleChange={onFiltersChangeSelect(
                                                'chauffeurName'
                                              )}
                                              options={bookingAndPartnerChauffeurs()}
                                              placeholder={
                                                partnerType === 'rental'
                                                  ? 'Agent'
                                                  : 'Chauffeur'
                                              }
                                              roundFormField={true}
                                            />
                                          </div>
                                          <div className=''>
                                            <FormSelect
                                              field={{
                                                name: 'chauffeur-search',
                                              }}
                                              handleChange={onFiltersChangeSelect(
                                                'chauffeurStatus'
                                              )}
                                              options={
                                                defaultChauffeurStatusPartner
                                              }
                                              placeholder='Chauffeur status'
                                              roundFormField={true}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {isCompletedTab ? (
                                        <>
                                          <button
                                            type='button'
                                            className='text-brown btn btn-link'
                                            style={{
                                              whiteSpace: 'pre',
                                              textShadow:
                                                'rgb(208, 2, 27) 0.25px 0px',
                                              paddingBottom: '0.9rem',
                                              maxWidth: 'fit-content',
                                              outline: 'none',
                                              boxShadow: 'none',
                                              fontSize: '14px',
                                            }}
                                            onClick={() => onClearFilters()}
                                          >
                                            Clear filters
                                          </button>
                                          <div className='d-flex justify-content-end'>
                                            <button
                                              type='button'
                                              className='text-brown btn btn-anchor'
                                              style={{
                                                whiteSpace: 'pre',
                                                textShadow:
                                                  'rgb(208, 2, 27) 0.25px 0px',
                                                marginTop: '0.8rem',
                                                maxWidth: 'fit-content',
                                                outline: 'none',
                                                boxShadow: 'none',
                                                fontSize: '15px',
                                                // textDecoration: 'underline',
                                                textUnderlineOffset: '6px',
                                              }}
                                              onClick={() => onExportBookings()}
                                            >
                                              Export CSV
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            type='button'
                                            className='text-brown btn btn-link'
                                            style={{
                                              whiteSpace: 'pre',
                                              textShadow:
                                                'rgb(208, 2, 27) 0.25px 0px',
                                              paddingBottom: '0.9rem',
                                              maxWidth: 'fit-content',
                                              outline: 'none',
                                              boxShadow: 'none',
                                              fontSize: '14px',
                                            }}
                                            onClick={() => onClearFilters()}
                                          >
                                            Clear filters
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className='partner-grid-container'>
                                      <div className=''>
                                        <FormFieldWithIcon
                                          field={{
                                            name: 'booking-number-search',
                                            placeHolder: `${
                                              isPlannedTab || isCompletedTab
                                                ? 'Booking number'
                                                : isPartner
                                                ? 'Offer number'
                                                : 'Booking number'
                                            }`,
                                            value: filters['number'],
                                            onChange: onFiltersChange('number'),
                                          }}
                                          icon='icons/Icon_Search_Inactive.png'
                                          roundFormField={true}
                                        />
                                      </div>
                                      <div className=''>
                                        <FormInputDatepicker
                                          field={{
                                            name: 'date-from-search',
                                            placeholder: 'From',
                                            value: filters['pickUpDate'],
                                            onChange: onDateChange(
                                              'pickUpDate'
                                            ),
                                          }}
                                          isISODate
                                          isSearchField={true}
                                          roundFormField={true}
                                        />
                                      </div>
                                      <div className=''>
                                        <FormInputDatepicker
                                          field={{
                                            name: 'date-to-search',
                                            placeholder: 'To',
                                            value: filters['dropOffDate'],
                                            onChange: onDateChange(
                                              'dropOffDate'
                                            ),
                                          }}
                                          isISODate
                                          isSearchField={true}
                                          roundFormField={true}
                                        />
                                      </div>
                                      <div className=''>
                                        <FormSelect
                                          field={{
                                            name: 'vehicle-search',
                                          }}
                                          handleChange={onFiltersChangeSelect(
                                            'vehicleName'
                                          )}
                                          options={bookingAndPartnerVehicles()}
                                          placeholder='Vehicle'
                                          roundFormField={true}
                                        />
                                      </div>
                                      <div className=''>
                                        <FormSelect
                                          field={{
                                            name: 'chauffeur-search',
                                          }}
                                          handleChange={onFiltersChangeSelect(
                                            'chauffeurName'
                                          )}
                                          options={bookingAndPartnerChauffeurs()}
                                          placeholder={
                                            partnerType === 'rental'
                                              ? 'Agent'
                                              : 'Chauffeur'
                                          }
                                          roundFormField={true}
                                        />
                                      </div>
                                      <div className=''>
                                        <FormSelect
                                          field={{
                                            name: 'booking-search',
                                          }}
                                          handleChange={onFiltersChangeSelect(
                                            'bookingStatus'
                                          )}
                                          options={
                                            isPlannedTab
                                              ? [
                                                  { label: 'All', value: '' },
                                                  {
                                                    label: 'Accepted',
                                                    value: 'accepted',
                                                  },
                                                  {
                                                    label: 'Declined',
                                                    value: 'declined',
                                                  },

                                                  {
                                                    label: 'Outsourced',
                                                    value: 'outsourced',
                                                  },
                                                ]
                                              : isCompletedTab
                                              ? [
                                                  { label: 'All', value: '' },
                                                  {
                                                    label: 'Accepted',
                                                    value: 'accepted',
                                                  },
                                                ]
                                              : [
                                                  { label: 'All', value: '' },
                                                  {
                                                    label: 'Pending',
                                                    value: 'in_review',
                                                  },
                                                ]
                                          }
                                          placeholder='Booking status'
                                          roundFormField={true}
                                        />
                                      </div>
                                      <div className=''>
                                        <FormSelect
                                          field={{
                                            name: 'chauffeur-search',
                                          }}
                                          handleChange={onFiltersChangeSelect(
                                            'chauffeurStatus'
                                          )}
                                          options={
                                            defaultChauffeurStatusPartner
                                          }
                                          placeholder='Chauffeur status'
                                          roundFormField={true}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                                {/* This is one of my worest css i ever made */}
                                {/* <div className='mb-4 mx-0 partner-grid-container'>
                                  <div className=''>
                                    <FormSelect
                                      field={{
                                        name: 'booking-search',
                                      }}
                                      handleChange={onFiltersChangeSelect(
                                        'bookingStatus'
                                      )}
                                      options={
                                        isPlannedTab
                                          ? [
                                              { label: 'All', value: '' },
                                              {
                                                label: 'Accepted',
                                                value: 'accepted',
                                              },
                                              {
                                                label: 'Declined',
                                                value: 'declined',
                                              },

                                              {
                                                label: 'Outsourced',
                                                value: 'outsourced',
                                              },
                                            ]
                                          : isCompletedTab
                                          ? [
                                              { label: 'All', value: '' },
                                              {
                                                label: 'Accepted',
                                                value: 'accepted',
                                              },
                                            ]
                                          : [
                                              { label: 'All', value: '' },
                                              {
                                                label: 'Pending',
                                                value: 'in_review',
                                              },
                                            ]
                                      }
                                      placeholder='Booking status'
                                      roundFormField={true}
                                    />
                                  </div>
                                  <div className=''>
                                    <FormSelect
                                      field={{
                                        name: 'chauffeur-search',
                                      }}
                                      handleChange={onFiltersChangeSelect(
                                        'chauffeurStatus'
                                      )}
                                      options={defaultChauffeurStatusPartner}
                                      placeholder='Chauffeur status'
                                      roundFormField={true}
                                    />
                                  </div>
                                </div> */}
                                <DriverBookingTable
                                  data={data}
                                  reloadList={reloadList}
                                  setIsLoading={setIsLoading}
                                  token={token}
                                  isPlannedTab={isPlannedTab}
                                  isNewBookingTab={isNewBookingTab}
                                  isCompletedTab={isCompletedTab}
                                  isBookingsLoading={isBookingsLoading}
                                  isLocalSupplier={isLocalSupplier}
                                />
                              </SmallUp>
                              <SmallDown>
                                <Row
                                  className='mb-0 mt-0'
                                  style={{ margin: '5%' }}
                                >
                                  <Col
                                    className='pl-0 pr-0'
                                    style={{ minWidth: 'calc(100vw - 40%)' }}
                                  >
                                    <FormFieldWithIcon
                                      field={{
                                        name: 'booking-number-search',
                                        placeHolder: `${
                                          isPlannedTab || isCompletedTab
                                            ? 'Booking number'
                                            : isPartner
                                            ? 'Offer number'
                                            : 'Booking number'
                                        }`,
                                        value: filters['number'],
                                        onChange: onFiltersChange('number'),
                                      }}
                                      icon='icons/Icon_Search_Inactive.png'
                                      roundFormField={true}
                                    />
                                  </Col>
                                  <Col className='pl-1 pr-0'>
                                    <div
                                      className='partner-filters'
                                      onClick={() => {
                                        setShowFilterModal(true)
                                      }}
                                    >
                                      <img
                                        src={
                                          filtersApplied
                                            ? '/images/icon-filter-active.png'
                                            : '/images/icon-filter-inactive.png'
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <DriverBookingCard
                                  data={data}
                                  reloadList={reloadList}
                                  setIsLoading={setIsLoading}
                                  token={token}
                                  isPlannedTab={isPlannedTab}
                                  isNewBookingTab={isNewBookingTab}
                                  isCompletedTab={isCompletedTab}
                                  isBookingsLoading={isBookingsLoading}
                                  onScrollBottom={() =>
                                    console.log('it gets called')
                                  }
                                />
                              </SmallDown>
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      }
                    </>
                  ) : (
                    <>
                      {!currentLocation.includes('home-page') && (
                        <TabsNav
                          align='start'
                          tabs={tabsToNavAccount}
                          className='light-tabs-partner'
                        />
                      )}
                      {!currentLocation.includes('settings') &&
                      !currentLocation.includes('home-page') ? (
                        <>
                          {isAccountLoading ? (
                            <LoadingSpinner />
                          ) : (
                            <>
                              <SmallUp>
                                <div
                                  className='driver-booking-container'
                                  style={{ margin: 0 }}
                                >
                                  {currentLocation.includes('chauffeurs') ? (
                                    <>
                                      <div className='chauffeur-count-container'>
                                        <img
                                          width={35}
                                          height={40}
                                          src={
                                            '/images/icons/icon-chauffeur-gold.png'
                                          }
                                          alt=''
                                        />
                                        {data && (
                                          <div>
                                            <span>{data.length}</span>
                                            {partnerType === 'rental' ? (
                                              <span>
                                                Active agent
                                                {data.length === 1 ? '' : 's'}
                                              </span>
                                            ) : (
                                              <span>
                                                Active chauffeur
                                                {data.length === 1 ? '' : 's'}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        className={`driver-service-card ${
                                          data && data.length <= 3
                                            ? 'assign-select-card'
                                            : 'assign-select-card-multiple'
                                        }`}
                                      >
                                        {' '}
                                        {partnerType === 'rental' ? (
                                          <div className='driver-service-card-header pb-4 pt-3'>
                                            <span>Agents</span>
                                          </div>
                                        ) : (
                                          <div className='driver-service-card-header pb-4 pt-3'>
                                            <span>Chauffeurs</span>
                                          </div>
                                        )}
                                        <div className='partner-search-container'>
                                          {data && data.length > 0 && (
                                            <div class='col pl-0'>
                                              <FormFieldWithIcon
                                                field={{
                                                  name: 'search-chauffeur',
                                                  placeHolder:
                                                    partnerType === 'rental'
                                                      ? 'Search agent name'
                                                      : 'Search chauffeur name',
                                                  value:
                                                    filters['chauffeurName'],
                                                  onChange: onFiltersChange(
                                                    'chauffeurName'
                                                  ),
                                                }}
                                                icon='icons/Icon_Search_Inactive.png'
                                                className='partner-search-bar'
                                              />
                                            </div>
                                          )}
                                          {data && data.length ? (
                                            <Link
                                              to={{
                                                pathname: `${
                                                  Host === partnerHostLive ||
                                                  Host === partnerHostStaging
                                                    ? '/'
                                                    : '/partner/'
                                                }driver/app/${token}/chauffeur/add`,
                                                state: {
                                                  isCarRental,
                                                },
                                              }}
                                              className='partner-link-desktop'
                                            >
                                              <Button
                                                className='partner-btn-desktop-secondary-assign select-vehicle'
                                                variant='brown'
                                              >
                                                {partnerType === 'rental'
                                                  ? 'Add agent'
                                                  : 'Add chauffeur'}
                                              </Button>
                                            </Link>
                                          ) : (
                                            <> </>
                                          )}
                                        </div>
                                        {data && data.length ? (
                                          <>
                                            {data && data.length <= 3 ? (
                                              data.map(item => (
                                                <div className='chauffeur-card-item'>
                                                  {item.profilePicture ? (
                                                    <div className='add-pic-container-uploaded'>
                                                      <img
                                                        className='profile-img'
                                                        src={
                                                          item.profilePicture
                                                        }
                                                        alt=''
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div className='add-pic-container-uploaded'>
                                                      <img
                                                        className='car-img-chauffeur'
                                                        src={
                                                          '/images/icons/icon-chauffeur-black.png'
                                                        }
                                                        alt=''
                                                      />
                                                    </div>
                                                  )}
                                                  <span
                                                    className='info-span-chauffeur'
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                    }}
                                                  >
                                                    <span
                                                      className='chauffeur-title d-block'
                                                      style={{
                                                        fontWeight: 'bolder',
                                                      }}
                                                    >
                                                      {item.firstName &&
                                                      item.lastName
                                                        ? `${formatSentenceCase(
                                                            item.firstName
                                                          )} ${formatSentenceCase(
                                                            item.lastName
                                                          )}`
                                                        : item.name}
                                                    </span>
                                                    <span
                                                      style={{
                                                        color: '#8b959e',
                                                      }}
                                                    >
                                                      {item.phoneNumber}
                                                    </span>
                                                  </span>
                                                  <Link
                                                    className='edit-img'
                                                    to={`${
                                                      Host ===
                                                        partnerHostLive ||
                                                      Host ===
                                                        partnerHostStaging
                                                        ? '/'
                                                        : '/partner/'
                                                    }driver/app/${token}/chauffeur/edit/${
                                                      item._id
                                                    }`}
                                                  >
                                                    <img
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      src='/images/edit_icon.png'
                                                      alt=''
                                                    />
                                                  </Link>
                                                </div>
                                              ))
                                            ) : (
                                              <div className='partner-grid-acc'>
                                                {multipleRowsList.map(items => (
                                                  <div>
                                                    {items.map(item => (
                                                      <div className='chauffeur-card-item'>
                                                        {item.profilePicture ? (
                                                          <div className='add-pic-container-uploaded'>
                                                            <img
                                                              className='profile-img'
                                                              src={
                                                                item.profilePicture
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                        ) : (
                                                          <div className='add-pic-container-uploaded'>
                                                            <img
                                                              className='car-img-chauffeur'
                                                              src={
                                                                '/images/icons/icon-chauffeur-black.png'
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                        )}
                                                        <span
                                                          className='info-span-chauffeur'
                                                          style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                              'column',
                                                          }}
                                                        >
                                                          <span
                                                            className='chauffeur-title d-block'
                                                            style={{
                                                              fontWeight:
                                                                'bolder',
                                                              textTransform:
                                                                'capitalize',
                                                            }}
                                                          >
                                                            {item.firstName &&
                                                            item.lastName
                                                              ? `${formatSentenceCase(
                                                                  item.firstName
                                                                )} ${formatSentenceCase(
                                                                  item.lastName
                                                                )}`
                                                              : formatSentenceCase(
                                                                  item.name
                                                                )}
                                                          </span>
                                                          <span
                                                            style={{
                                                              color: '#8b959e',
                                                            }}
                                                          >
                                                            {item.phoneNumber}
                                                          </span>
                                                        </span>
                                                        <Link
                                                          className='edit-img'
                                                          to={`${
                                                            Host ===
                                                              partnerHostLive ||
                                                            Host ===
                                                              partnerHostStaging
                                                              ? '/'
                                                              : '/partner/'
                                                          }driver/app/${token}/chauffeur/edit/${
                                                            item._id
                                                          }`}
                                                        >
                                                          <img
                                                            style={{
                                                              cursor: 'pointer',
                                                            }}
                                                            src='/images/edit_icon.png'
                                                          />
                                                        </Link>
                                                      </div>
                                                    ))}
                                                  </div>
                                                ))}
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <div className='chauffeur-card-item extra-spacing'>
                                            <div className='d-flex align-items-center'>
                                              <div className='add-pic-container'>
                                                <img
                                                  className='car-img-chauffeur'
                                                  src={
                                                    '/images/icons/icon-chauffeur-grey.png'
                                                  }
                                                  alt=''
                                                />
                                              </div>
                                              <span
                                                className='info-span-small ml-3'
                                                style={{ whiteSpace: 'pre' }}
                                              >
                                                <span
                                                  style={{ color: '#8b959e' }}
                                                >
                                                  No active{' '}
                                                  {partnerType === 'rental'
                                                    ? 'agent'
                                                    : 'chauffeur'}
                                                </span>
                                              </span>
                                            </div>
                                            <Link
                                              to={{
                                                pathname: `${
                                                  Host === partnerHostLive ||
                                                  Host === partnerHostStaging
                                                    ? '/'
                                                    : '/partner/'
                                                }driver/app/${token}/chauffeur/add`,
                                                state: {
                                                  isCarRental,
                                                },
                                              }}
                                            >
                                              <Button
                                                className='partner-btn-desktop-small select-vehicle'
                                                variant='brown'
                                                style={{
                                                  border: '1px solid #a79779',
                                                  backgroundColor: '#a79779',
                                                }}
                                              >
                                                Add{' '}
                                                {partnerType === 'rental'
                                                  ? 'agent'
                                                  : 'chauffeur'}
                                              </Button>
                                            </Link>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {isPartner && isPartnerEarnings ? (
                                        <div className='partner-card'>
                                          <Commissions token={partnerToken} />
                                        </div>
                                      ) : isPartner && isPartnerPayouts ? (
                                        <PartnerPayout
                                          partnerToken={partnerToken}
                                        />
                                      ) : (
                                        <>
                                          <div className='chauffeur-count-container'>
                                            <img
                                              width={70}
                                              height={25}
                                              src={
                                                '/images/icons/icon-vehicle-gold.png'
                                              }
                                              style={{ marginTop: '12px' }}
                                              alt=''
                                            />
                                            {data && (
                                              <div>
                                                <span>{data.length}</span>
                                                <span>
                                                  Active vehicle
                                                  {data.length === 1 ? '' : 's'}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                          <div
                                            className={`driver-service-card ${
                                              data && data.length <= 3
                                                ? 'assign-select-card'
                                                : 'assign-select-card-multiple'
                                            }`}
                                          >
                                            {' '}
                                            <div className='driver-service-card-header pb-4 pt-3'>
                                              <span>Vehicles</span>
                                            </div>
                                            <div className='partner-search-container'>
                                              {data && data.length > 0 && (
                                                <div class='col pl-0'>
                                                  <FormFieldWithIcon
                                                    field={{
                                                      name: 'search-vehicle',
                                                      placeHolder:
                                                        'Search vehicle',
                                                      value:
                                                        filters['vehicleName'],
                                                      onChange: onFiltersChange(
                                                        'vehicleName'
                                                      ),
                                                    }}
                                                    icon='icons/Icon_Search_Inactive.png'
                                                    className='partner-search-bar'
                                                  />
                                                </div>
                                              )}
                                              {data && data.length ? (
                                                <Link
                                                  to={`${
                                                    Host === partnerHostLive ||
                                                    Host === partnerHostStaging
                                                      ? '/'
                                                      : '/partner/'
                                                  }driver/app/${token}/car/add`}
                                                  className='partner-link-desktop'
                                                >
                                                  <Button
                                                    className='partner-btn-desktop-secondary-assign select-vehicle'
                                                    variant='brown'
                                                  >
                                                    Add vehicle
                                                  </Button>
                                                </Link>
                                              ) : (
                                                <> </>
                                              )}
                                            </div>
                                            {data && data.length ? (
                                              <>
                                                {data && data.length <= 3 ? (
                                                  data.map(item => (
                                                    <div className='chauffeur-card-item'>
                                                      <img
                                                        className=' d-block car-edit-img'
                                                        src={
                                                          item.vehicleImage &&
                                                          item.vehicleImage
                                                        }
                                                        alt=''
                                                      />
                                                      <span className='info-span'>
                                                        <span
                                                          className='d-block'
                                                          style={{
                                                            fontWeight:
                                                              'bolder',
                                                          }}
                                                        >
                                                          {item.make &&
                                                            item.make
                                                              .label}{' '}
                                                          {item.model &&
                                                            item.model.label}
                                                        </span>
                                                        <span
                                                          style={{
                                                            color: '#8b959e',
                                                            textTransform:
                                                              'uppercase',
                                                          }}
                                                        >
                                                          {item.plateNumber}
                                                        </span>
                                                      </span>
                                                      <Link
                                                        className='edit-img'
                                                        to={`${
                                                          Host ===
                                                            partnerHostLive ||
                                                          Host ===
                                                            partnerHostStaging
                                                            ? '/'
                                                            : '/partner/'
                                                        }driver/app/${token}/car/edit/${
                                                          item._id
                                                        }`}
                                                      >
                                                        <img
                                                          style={{
                                                            cursor: 'pointer',
                                                          }}
                                                          src='/images/edit_icon.png'
                                                        />
                                                      </Link>
                                                    </div>
                                                  ))
                                                ) : (
                                                  <div className='partner-grid-acc'>
                                                    {multipleRowsList.map(
                                                      items => (
                                                        <div>
                                                          {items.map(item => (
                                                            <div className='chauffeur-card-item'>
                                                              <img
                                                                className=' d-block car-edit-img'
                                                                src={
                                                                  item.vehicleImage &&
                                                                  item.vehicleImage
                                                                }
                                                                alt=''
                                                              />
                                                              <span className='info-span'>
                                                                <span
                                                                  className='d-block'
                                                                  style={{
                                                                    fontWeight:
                                                                      'bolder',
                                                                  }}
                                                                >
                                                                  {item.make &&
                                                                    item.make
                                                                      .label}{' '}
                                                                  {item.model &&
                                                                    item.model
                                                                      .label}
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      '#8b959e',
                                                                    textTransform:
                                                                      'uppercase',
                                                                  }}
                                                                >
                                                                  {
                                                                    item.plateNumber
                                                                  }
                                                                </span>
                                                              </span>
                                                              <Link
                                                                className='edit-img'
                                                                to={`${
                                                                  Host ===
                                                                    partnerHostLive ||
                                                                  Host ===
                                                                    partnerHostStaging
                                                                    ? '/'
                                                                    : '/partner/'
                                                                }driver/app/${token}/car/edit/${
                                                                  item._id
                                                                }`}
                                                              >
                                                                <img
                                                                  style={{
                                                                    cursor:
                                                                      'pointer',
                                                                  }}
                                                                  src='/images/edit_icon.png'
                                                                />
                                                              </Link>
                                                            </div>
                                                          ))}
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <div className='chauffeur-card-item extra-spacing'>
                                                <div className='d-flex align-items-center'>
                                                  <div className='add-pic-container'>
                                                    <img
                                                      className='car-img'
                                                      src={
                                                        '/images/icons/icon-vehicle-grey.png'
                                                      }
                                                      alt=''
                                                    />
                                                  </div>
                                                  <span
                                                    className='info-span-small ml-3'
                                                    style={{
                                                      whiteSpace: 'pre',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: '#8b959e',
                                                      }}
                                                    >
                                                      No active vehicle
                                                    </span>
                                                  </span>
                                                </div>
                                                <Link
                                                  to={`${
                                                    Host === partnerHostLive ||
                                                    Host === partnerHostStaging
                                                      ? '/'
                                                      : '/partner/'
                                                  }driver/app/${token}/car/add`}
                                                >
                                                  <Button
                                                    className='partner-btn-desktop-small select-vehicle'
                                                    variant='brown'
                                                    style={{
                                                      border:
                                                        '1px solid #a79779',
                                                      backgroundColor:
                                                        '#a79779',
                                                    }}
                                                  >
                                                    Add vehicle
                                                  </Button>
                                                </Link>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </SmallUp>
                              <SmallDown>
                                {isPartner && (
                                  <>
                                    {currentLocation.includes('cars') ? (
                                      <div class='col pl-0 pr-0 mb-3 app-search-field'>
                                        <FormFieldWithIcon
                                          field={{
                                            name: 'search-vehicle',
                                            placeHolder: 'Search vehicle',
                                            value: filters['vehicleName'],
                                            onChange: onFiltersChange(
                                              'vehicleName'
                                            ),
                                          }}
                                          icon='icons/Icon_Search_Inactive.png'
                                          className='partner-search-bar'
                                        />
                                      </div>
                                    ) : (
                                      <div class='col pl-0 pr-0 mb-3 app-search-field'>
                                        <FormFieldWithIcon
                                          field={{
                                            name: 'search-chauffeur',
                                            placeHolder:
                                              partnerType === 'rental'
                                                ? 'Search agent name'
                                                : 'Search chauffeur name',
                                            value: filters['chauffeurName'],
                                            onChange: onFiltersChange(
                                              'chauffeurName'
                                            ),
                                          }}
                                          icon='icons/Icon_Search_Inactive.png'
                                          className='partner-search-bar'
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                                <div style={{ paddingBottom: '5rem' }}>
                                  {isPartner && (
                                    <AddCard
                                      type={
                                        currentLocation.includes('cars')
                                          ? 'car'
                                          : 'chauffeur'
                                      }
                                      token={token}
                                      partnerType={partnerType}
                                    />
                                  )}

                                  {data &&
                                    Object.entries(data).length !== 0 &&
                                    data.map((item, i) => (
                                      <InfoCard
                                        type={
                                          currentLocation.includes('chauffeurs')
                                            ? 'chauffeur'
                                            : 'car'
                                        }
                                        item={item}
                                        token={token}
                                        style={{ marginBottom: '2em' }}
                                      />
                                    ))}
                                </div>
                              </SmallDown>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {currentLocation.includes('settings') ? (
                            <>
                              {isSettingsLoading ? (
                                <LoadingSpinner />
                              ) : (
                                <>
                                  <SmallUp>
                                    {showEarningIndicator &&
                                    !isMobileBrowser ? (
                                      <div className='warning-partner-container'>
                                        <div className='warning-text'>
                                          <img
                                            className='pdf-icon'
                                            src='/images/icons/payment/warning_message_icon.png'
                                            alt=''
                                          />
                                          <span>
                                            Your entity is missing important
                                            company details. ROLZO requires the
                                            information to verify your business
                                            and pay your earnings.
                                          </span>
                                        </div>
                                        <Button
                                          style={{
                                            whiteSpace: 'nowrap',
                                            marginRight: '1em',
                                            marginTop: '0.5em',
                                          }}
                                          className='inline-edit-btn btn-anchor btn-anchor'
                                          variant='link'
                                          onClick={() => {
                                            setShowEarningPopUp(true)
                                          }}
                                        >
                                          Update company profile
                                        </Button>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <div
                                      className='driver-booking-container'
                                      style={{ margin: 0 }}
                                    >
                                      <div className='driver-service-card assign-card'>
                                        <div className='driver-service-card-header pb-4 pt-3'>
                                          <span>Company</span>
                                        </div>
                                        {settingData && (
                                          <div className='chauffeur-card-item mb-4'>
                                            {settingData.logo ? (
                                              <div className='add-pic-container-uploaded'>
                                                <img
                                                  className='profile-img'
                                                  src={settingData.logo}
                                                  alt=''
                                                />
                                              </div>
                                            ) : (
                                              <div className='add-pic-container-uploaded'>
                                                <img
                                                  width={18}
                                                  src={
                                                    '/images/icon-company-black.png'
                                                  }
                                                  alt=''
                                                />
                                              </div>
                                            )}
                                            <span className='info-span-company'>
                                              <span
                                                className='d-block'
                                                style={{ fontWeight: 'bolder' }}
                                              >
                                                {settingData && settingData.name
                                                  ? settingData.name
                                                  : 'N/A'}
                                              </span>
                                            </span>
                                            <Link
                                              className='edit-img'
                                              to={`${
                                                Host === partnerHostLive ||
                                                Host === partnerHostStaging
                                                  ? '/'
                                                  : '/partner/'
                                              }driver/app/${token}/settings/edit/${
                                                settingData._id
                                              }`}
                                            >
                                              <img
                                                src='/images/edit_icon.png'
                                                alt=''
                                              />
                                            </Link>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </SmallUp>
                                  <SmallDown>
                                    <InfoCard
                                      type={'settings'}
                                      item={settingData ? settingData : data}
                                      token={token}
                                      style={{ marginBottom: '2em' }}
                                    />
                                  </SmallDown>
                                </>
                              )}
                            </>
                          ) : (
                            //Home page
                            <>
                              {homePageInfo &&
                              userName &&
                              userName !== 'newUser' ? (
                                <>
                                  {isPartner ? (
                                    <div
                                      className=' driver-booking-container'
                                      style={{ paddingBottom: '5rem' }}
                                    >
                                      {/* Up coming actions card */}
                                      <div className='driver-service-card'>
                                        <span className='driver-service-card-header mb-4'>
                                          Upcoming actions
                                        </span>
                                        {homePageInfo.upcomingActions &&
                                        homePageInfo.upcomingActions.length ? (
                                          <UpcomingActions
                                            viewBooking={viewBooking}
                                            actions={(() => {
                                              if (partnerType === 'rental') {
                                                return homePageInfo.upcomingActions.filter(
                                                  action =>
                                                    action.type ===
                                                    'car_rentals'
                                                )
                                              } else {
                                                return homePageInfo.upcomingActions.filter(
                                                  action =>
                                                    action.type !==
                                                    'car_rentals'
                                                )
                                              }
                                            })()}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      {/* Today's Bookings */}
                                      {partnerType !== 'rental' ? (
                                        <Col
                                          md={6}
                                          style={{
                                            height: 'fit-content',
                                          }}
                                          className='driver-service-card'
                                        >
                                          <span className='driver-service-card-header mb-4'>
                                            Today
                                          </span>
                                          <div>
                                            <div className='d-flex mb-4 mt-4'>
                                              <div className='align-self-center'>
                                                <img
                                                  src='/images/icons/icon-bookings-gold.png'
                                                  alt=''
                                                  width={25}
                                                />
                                              </div>
                                              <div
                                                className='d-flex flex-column'
                                                style={{
                                                  paddingLeft: '1.7rem',
                                                }}
                                              >
                                                <span className='counter'>
                                                  {
                                                    homePageInfo.todaysBookings
                                                      .length
                                                  }
                                                </span>
                                                <span className='card-message'>
                                                  Bookings
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <>
                                            {homePageInfo &&
                                            homePageInfo.todaysBookings
                                              ? homePageInfo.todaysBookings.map(
                                                  item => (
                                                    <div className='grey-background'>
                                                      <Row>
                                                        <Col className='d-flex justify-content-between'>
                                                          <span className=' offer-date'>
                                                            {moment
                                                              .utc(item.date)
                                                              .format(
                                                                'ddd D MMM YY, HH:mm'
                                                              )}
                                                          </span>
                                                          <span className='price-currency'>{`${item.price} ${item.currency}`}</span>
                                                        </Col>
                                                      </Row>
                                                      <Row className='d-flex align-items-center mt-1 justify-content-between'>
                                                        <Col className='col pt-1 pr-0 '>
                                                          {item.status ===
                                                            'confirmed' ||
                                                          item.status ===
                                                            'change_request' ? (
                                                            <span className='newOffer-message'>
                                                              {`${item.type} • ${item.passengerName}`}
                                                            </span>
                                                          ) : (
                                                            <span className='newOffer-message'>
                                                              {item.type}
                                                            </span>
                                                          )}
                                                          <span className='newOffer-message pt-1'>
                                                            {item.modelName}
                                                          </span>
                                                        </Col>
                                                        <Col className='col d-flex justify-content-end pt-1 pl-0'>
                                                          <Button
                                                            className='partner-btn-desktop-primary select-vehicle'
                                                            variant='brown'
                                                            onClick={() => {
                                                              viewBooking(
                                                                item.bookingId
                                                              )
                                                            }}
                                                          >
                                                            View
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  )
                                                )
                                              : ''}
                                          </>
                                        </Col>
                                      ) : (
                                        <Col
                                          md={6}
                                          style={{
                                            height: 'fit-content',
                                          }}
                                          className='driver-service-card'
                                        >
                                          <span className='driver-service-card-header mb-4'>
                                            Today
                                          </span>
                                          <div>
                                            <div className='d-flex mb-4 mt-4'>
                                              <div className='align-self-center'>
                                                <img
                                                  src='/images/icons/icon-bookings-gold.png'
                                                  alt=''
                                                  width={25}
                                                />
                                              </div>
                                              <div
                                                className='d-flex flex-column'
                                                style={{
                                                  paddingLeft: '1.7rem',
                                                }}
                                              >
                                                <span className='counter'>
                                                  {
                                                    homePageInfo
                                                      .todaysRentalBookings
                                                      .length
                                                  }
                                                </span>
                                                <span className='card-message'>
                                                  Bookings
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <>
                                            {homePageInfo &&
                                            homePageInfo.todaysRentalBookings
                                              ? homePageInfo.todaysRentalBookings.map(
                                                  item => (
                                                    <div className='grey-background'>
                                                      <Row>
                                                        <Col className='d-flex justify-content-between'>
                                                          <span className=' offer-date'>
                                                            {moment
                                                              .utc(item.date)
                                                              .format(
                                                                'ddd D MMM YY, HH:mm'
                                                              )}
                                                          </span>
                                                          <span className='price-currency'>{`${item.price} ${item.currency}`}</span>
                                                        </Col>
                                                      </Row>
                                                      <Row className='d-flex align-items-center mt-1 justify-content-between'>
                                                        <Col className='col pt-1 pr-0 '>
                                                          {item.status ===
                                                            'confirmed' ||
                                                          item.status ===
                                                            'change_request' ? (
                                                            <span className='newOffer-message'>
                                                              {`${item.type} • ${item.passengerName}`}
                                                            </span>
                                                          ) : (
                                                            <span className='newOffer-message'>
                                                              {item.type}
                                                            </span>
                                                          )}
                                                          <span className='newOffer-message pt-1'>
                                                            {item.modelName}
                                                          </span>
                                                        </Col>
                                                        <Col className='col d-flex justify-content-end pt-1 pl-0'>
                                                          <Button
                                                            className='partner-btn-desktop-primary select-vehicle'
                                                            variant='brown'
                                                            onClick={() => {
                                                              viewBooking(
                                                                item.bookingId
                                                              )
                                                            }}
                                                          >
                                                            View
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  )
                                                )
                                              : ''}
                                          </>
                                        </Col>
                                      )}
                                      {/* New offers */}
                                      {partnerType !== 'rental' ? (
                                        <Col
                                          md={6}
                                          style={{
                                            height: 'fit-content',
                                          }}
                                          className='driver-service-card'
                                        >
                                          <span className='driver-service-card-header mb-4'>
                                            New offers
                                          </span>
                                          <div>
                                            <div className='d-flex mb-4 mt-4'>
                                              <div className='align-self-center'>
                                                <img
                                                  src='/images/icons/icon-bookings-gold.png'
                                                  alt=''
                                                  width={25}
                                                />
                                              </div>
                                              <div
                                                className='d-flex flex-column'
                                                style={{
                                                  paddingLeft: '1.7rem',
                                                }}
                                              >
                                                <span className='counter'>
                                                  {
                                                    homePageInfo.newOffers
                                                      .length
                                                  }
                                                </span>
                                                <span className='card-message'>
                                                  New offers
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <>
                                            {homePageInfo &&
                                            homePageInfo.newOffers
                                              ? homePageInfo.newOffers.map(
                                                  item => (
                                                    <div className='grey-background'>
                                                      <Row>
                                                        <Col className='d-flex justify-content-between'>
                                                          <span className=' offer-date'>
                                                            {moment
                                                              .utc(item.date)
                                                              .format(
                                                                'ddd D MMM YY, HH:mm'
                                                              )}
                                                          </span>
                                                          <span className='price-currency'>{`${item.price} ${item.currency}`}</span>
                                                        </Col>
                                                      </Row>
                                                      <Row className='d-flex align-items-center justify-content-between mt-1'>
                                                        <Col className='col-auto pt-1 pr-0 '>
                                                          <span className='newOffer-message'>
                                                            {item.type}
                                                          </span>
                                                          <span className='newOffer-message pt-1'>
                                                            {item.modelName}
                                                          </span>
                                                        </Col>
                                                        <Col className=' col-auto d-flex justify-content-end pt-1 pl-0'>
                                                          <Button
                                                            className='partner-btn-desktop-primary select-vehicle'
                                                            variant='brown'
                                                            onClick={() => {
                                                              viewBooking(
                                                                item.bookingId
                                                              )
                                                            }}
                                                          >
                                                            View
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  )
                                                )
                                              : ''}
                                          </>
                                        </Col>
                                      ) : (
                                        <Col
                                          md={6}
                                          style={{
                                            height: 'fit-content',
                                          }}
                                          className='driver-service-card'
                                        >
                                          <span className='driver-service-card-header mb-4'>
                                            New offers
                                          </span>
                                          <div>
                                            <div className='d-flex mb-4 mt-4'>
                                              <div className='align-self-center'>
                                                <img
                                                  src='/images/icons/icon-bookings-gold.png'
                                                  alt=''
                                                  width={25}
                                                />
                                              </div>
                                              <div
                                                className='d-flex flex-column'
                                                style={{
                                                  paddingLeft: '1.7rem',
                                                }}
                                              >
                                                <span className='counter'>
                                                  {
                                                    homePageInfo.newRentalOffers
                                                      .length
                                                  }
                                                </span>
                                                <span className='card-message'>
                                                  New offers
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <>
                                            {homePageInfo &&
                                            homePageInfo.newRentalOffers
                                              ? homePageInfo.newRentalOffers.map(
                                                  item => (
                                                    <div className='grey-background'>
                                                      <Row>
                                                        <Col className='d-flex justify-content-between'>
                                                          <span className=' offer-date'>
                                                            {moment
                                                              .utc(item.date)
                                                              .format(
                                                                'ddd D MMM YY, HH:mm'
                                                              )}
                                                          </span>
                                                          <span className='price-currency'>{`${item.price} ${item.currency}`}</span>
                                                        </Col>
                                                      </Row>
                                                      <Row className='d-flex align-items-center justify-content-between mt-1'>
                                                        <Col className='col-auto pt-1 pr-0 '>
                                                          <span className='newOffer-message'>
                                                            {item.type}
                                                          </span>
                                                          <span className='newOffer-message pt-1'>
                                                            {item.modelName}
                                                          </span>
                                                        </Col>
                                                        <Col className=' col-auto d-flex justify-content-end pt-1 pl-0'>
                                                          <Button
                                                            className='partner-btn-desktop-primary select-vehicle'
                                                            variant='brown'
                                                            onClick={() => {
                                                              viewBooking(
                                                                item.bookingId
                                                              )
                                                            }}
                                                          >
                                                            View
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  )
                                                )
                                              : ''}
                                          </>
                                        </Col>
                                      )}
                                      {/* Total earnings card */}
                                      <div className='driver-service-card'>
                                        <span className='driver-service-card-header mb-4'>
                                          Earnings
                                        </span>
                                        <div className='d-flex '>
                                          <div className='align-self-center'>
                                            <img
                                              src='/images/icons/icon-earnings.png'
                                              alt=''
                                              width={25}
                                            />
                                          </div>
                                          <div
                                            className='d-flex flex-column'
                                            style={{ paddingLeft: '2.7rem' }}
                                          >
                                            <span className='counter'>{`${numberWithCommas(
                                              numberWithCommas(
                                                parseFloat(
                                                  partnerType === 'rental'
                                                    ? homePageInfo.carRentalEarnings
                                                    : parseFloat(
                                                        homePageInfo.totalEarnings
                                                      ) -
                                                        parseFloat(
                                                          homePageInfo.carRentalEarnings
                                                        )
                                                ).toFixed(2)
                                              )
                                            )} ${homePageInfo.currency.toUpperCase()}`}</span>
                                            <span className='card-message'>
                                              Total earnings
                                            </span>
                                          </div>
                                        </div>
                                        {partnerType !== 'rental' ? (
                                          <>
                                            <div className='chauffeur-card-item'>
                                              <span className='partner-bookingType'>{`Transfers (${homePageInfo.transferBookingsCounter}) `}</span>
                                              <span
                                                style={{ fontSize: '16px' }}
                                              >{`${numberWithCommas(
                                                numberWithCommas(
                                                  parseFloat(
                                                    homePageInfo.transferEarnings
                                                  ).toFixed(2)
                                                )
                                              )} ${homePageInfo.currency.toUpperCase()}`}</span>
                                            </div>
                                            <div className='chauffeur-card-item'>
                                              <span className='partner-bookingType'>{`By the hour (${homePageInfo.hourlyBookingsCounter}) `}</span>
                                              <span
                                                style={{ fontSize: '16px' }}
                                              >{`${numberWithCommas(
                                                numberWithCommas(
                                                  parseFloat(
                                                    homePageInfo.hourlyEarnings
                                                  ).toFixed(2)
                                                )
                                              )} ${homePageInfo.currency.toUpperCase()}`}</span>
                                            </div>
                                          </>
                                        ) : (
                                          <div className='chauffeur-card-item'>
                                            <span className='partner-bookingType'>{`Car rental (${homePageInfo.carRentalBookingsCounter}) `}</span>
                                            <span
                                              style={{ fontSize: '16px' }}
                                            >{`${numberWithCommas(
                                              numberWithCommas(
                                                parseFloat(
                                                  homePageInfo.carRentalEarnings
                                                ).toFixed(2)
                                              )
                                            )} ${homePageInfo.currency.toUpperCase()}`}</span>
                                          </div>
                                        )}
                                      </div>
                                      {/* Chauffeur card */}
                                      <div className='driver-service-card'>
                                        {partnerType === 'rental' ? (
                                          <span className='driver-service-card-header mb-4'>
                                            Agents
                                          </span>
                                        ) : (
                                          <span className='driver-service-card-header mb-4'>
                                            Chauffeurs
                                          </span>
                                        )}
                                        <div className='d-flex '>
                                          <div className='align-self-center'>
                                            <img
                                              src='/images/icons/icon-chauffeur-gold.png'
                                              alt=''
                                              width={25}
                                            />
                                          </div>
                                          <div
                                            className='d-flex flex-column'
                                            style={{ paddingLeft: '2.7rem' }}
                                          >
                                            <span className='counter'>
                                              {homePageInfo.chauffeurs}
                                            </span>
                                            <span className='card-message'>
                                              Active{' '}
                                              {partnerType === 'rental'
                                                ? 'agents'
                                                : 'chauffeurs'}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      {/* Vehicle card */}
                                      <div className='driver-service-card'>
                                        <span className='driver-service-card-header mb-3'>
                                          Vehicles
                                        </span>
                                        <div className='d-flex '>
                                          <div className='align-self-center'>
                                            <img
                                              style={{
                                                verticalAlign: 'bottom',
                                              }}
                                              src='/images/icons/icon-vehicle-gold.png'
                                              alt=''
                                              width={40}
                                            />
                                          </div>
                                          <div
                                            className='d-flex flex-column'
                                            style={{ paddingLeft: '1.9rem' }}
                                          >
                                            <span className='counter'>
                                              {homePageInfo.vehicles}
                                            </span>
                                            <span className='card-message'>
                                              Active vehicles
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className=' driver-booking-container'
                                      // style={{ paddingBottom: '5rem' }}
                                    >
                                      {/* Today's Bookings */}
                                      {partnerType !== 'rental' ? (
                                        <Col
                                          md={6}
                                          style={{
                                            height: 'fit-content',
                                          }}
                                          className='driver-service-card'
                                        >
                                          <span className='driver-service-card-header mb-4'>
                                            Today
                                          </span>
                                          <div>
                                            <div className='d-flex mb-4 mt-4'>
                                              <div className='align-self-center'>
                                                <img
                                                  src='/images/icons/icon-bookings-gold.png'
                                                  alt=''
                                                  width={25}
                                                />
                                              </div>
                                              <div
                                                className='d-flex flex-column'
                                                style={{
                                                  paddingLeft: '1.7rem',
                                                }}
                                              >
                                                <span className='counter'>
                                                  {
                                                    homePageInfo.todaysBookings
                                                      .length
                                                  }
                                                </span>
                                                <span className='card-message'>
                                                  Bookings
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <>
                                            {homePageInfo &&
                                            homePageInfo.todaysBookings
                                              ? homePageInfo.todaysBookings.map(
                                                  item => (
                                                    <div className='grey-background'>
                                                      <Row>
                                                        <Col className='d-flex justify-content-between'>
                                                          <span className=' offer-date'>
                                                            {moment
                                                              .utc(item.date)
                                                              .format(
                                                                'ddd D MMM YY, HH:mm'
                                                              )}
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                      <Row className='d-flex align-items-center justify-content-between mt-1'>
                                                        <Col className=' col-auto pt-1 pr-0'>
                                                          {item.status ===
                                                            'confirmed' ||
                                                          item.status ===
                                                            'change_request' ? (
                                                            <span className='newOffer-message'>
                                                              {`${item.type} • ${item.passengerName}`}
                                                            </span>
                                                          ) : (
                                                            <span className='newOffer-message'>
                                                              {item.type}
                                                            </span>
                                                          )}
                                                          <span className='newOffer-message pt-1'>
                                                            {item.vehicle &&
                                                            item.vehicle
                                                              .length > 15
                                                              ? `${item.vehicle.substring(
                                                                  0,
                                                                  11
                                                                )}...`
                                                              : item.vehicle}
                                                          </span>
                                                        </Col>
                                                        <Col className='col-auto d-flex justify-content-end pt-1 pl-0'>
                                                          <Button
                                                            className='partner-btn-desktop-primary select-vehicle'
                                                            variant='brown'
                                                            onClick={() => {
                                                              viewBooking(
                                                                item.bookingId
                                                              )
                                                            }}
                                                          >
                                                            View
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  )
                                                )
                                              : ''}
                                          </>
                                        </Col>
                                      ) : (
                                        <Col
                                          md={6}
                                          style={{
                                            height: 'fit-content',
                                          }}
                                          className='driver-service-card'
                                        >
                                          <span className='driver-service-card-header mb-4'>
                                            Today
                                          </span>
                                          <div>
                                            <div className='d-flex mb-4 mt-4'>
                                              <div className='align-self-center'>
                                                <img
                                                  src='/images/icons/icon-bookings-gold.png'
                                                  alt=''
                                                  width={25}
                                                />
                                              </div>
                                              <div
                                                className='d-flex flex-column'
                                                style={{
                                                  paddingLeft: '1.7rem',
                                                }}
                                              >
                                                <span className='counter'>
                                                  {
                                                    homePageInfo
                                                      .todaysRentalBookings
                                                      .length
                                                  }
                                                </span>
                                                <span className='card-message'>
                                                  Bookings
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <>
                                            {homePageInfo &&
                                            homePageInfo.todaysRentalBookings
                                              ? homePageInfo.todaysRentalBookings.map(
                                                  item => (
                                                    <div className='grey-background'>
                                                      <Row>
                                                        <Col className='d-flex justify-content-between'>
                                                          <span className=' offer-date'>
                                                            {moment
                                                              .utc(item.date)
                                                              .format(
                                                                'ddd D MMM YY, HH:mm'
                                                              )}
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                      <Row className='d-flex align-items-center justify-content-between mt-1'>
                                                        <Col className=' col-auto pt-1 pr-0'>
                                                          {item.status ===
                                                            'confirmed' ||
                                                          item.status ===
                                                            'change_request' ? (
                                                            <span className='newOffer-message'>
                                                              {`${item.type} • ${item.passengerName}`}
                                                            </span>
                                                          ) : (
                                                            <span className='newOffer-message'>
                                                              {item.type}
                                                            </span>
                                                          )}
                                                          <span className='newOffer-message pt-1'>
                                                            {item.vehicle &&
                                                            item.vehicle
                                                              .length > 15
                                                              ? `${item.vehicle.substring(
                                                                  0,
                                                                  11
                                                                )}...`
                                                              : item.vehicle}
                                                          </span>
                                                        </Col>
                                                        <Col className='col-auto d-flex justify-content-end pt-1 pl-0'>
                                                          <Button
                                                            className='partner-btn-desktop-primary select-vehicle'
                                                            variant='brown'
                                                            onClick={() => {
                                                              viewBooking(
                                                                item.bookingId
                                                              )
                                                            }}
                                                          >
                                                            View
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  )
                                                )
                                              : ''}
                                          </>
                                        </Col>
                                      )}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {userName === 'newUser' ? (
                                    <></>
                                  ) : (
                                    <LoadingSpinner />
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </main>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ overflow: 'auto' }}>
          <div
            className='driver-booking-container-header-booking'
            style={hasNotch ? { marginTop: '2rem' } : { marginTop: '0px' }}
          >
            <img
              className='driver-back-icon'
              style={{ width: '12px', top: '7px' }}
              src='/images/icons/close-icon.png'
              alt=''
              onClick={() => {
                setShowFilterModal(false)
              }}
            />
            <span style={{ paddingTop: '0.2em' }}>Filters</span>
            <span style={{ paddingTop: '0.2em' }}></span>
          </div>
          <div className='partner-filter-container'>
            <Col>
              <div className='md-4 mx-2'>
                <FormInputDatepicker
                  field={{
                    name: 'date-from-search',
                    placeholder: 'From',
                    value: filters['pickUpDate'],
                    onChange: onDateChange('pickUpDate'),
                  }}
                  isISODate
                  isSearchField={true}
                  roundFormField={true}
                />
              </div>
              <div className='md-4 mx-2'>
                <FormInputDatepicker
                  field={{
                    name: 'date-to-search',
                    placeholder: 'To',
                    value: filters['dropOffDate'],
                    onChange: onDateChange('dropOffDate'),
                  }}
                  isISODate
                  isSearchField={true}
                  roundFormField={true}
                />
              </div>
              <div className='md-4 mx-2'>
                <FormSelect
                  field={
                    filters['vehicleName'] === '' || filters['vehicleName']
                      ? {
                          name: 'vehicle-search',
                          value: {
                            label:
                              filters['vehicleName'] === ''
                                ? 'All'
                                : filters['vehicleName'],
                            value: filters['vehicleName'],
                          },
                        }
                      : { name: 'vehicle-search' }
                  }
                  handleChange={onFiltersChangeSelect('vehicleName')}
                  options={bookingAndPartnerVehicles()}
                  placeholder='Vehicle'
                  roundFormField={true}
                />
              </div>
              <div className='md-4 mx-2'>
                <FormSelect
                  field={
                    filters['chauffeurName'] === '' || filters['chauffeurName']
                      ? {
                          name: 'chauffeur-search',
                          value: {
                            label:
                              filters['chauffeurName'] === ''
                                ? 'All'
                                : filters['chauffeurName'],
                            value: filters['chauffeurName'],
                          },
                        }
                      : { name: 'chauffeur-search' }
                  }
                  handleChange={onFiltersChangeSelect('chauffeurName')}
                  options={bookingAndPartnerChauffeurs()}
                  placeholder={partnerType === 'rental' ? 'Agent' : 'Chauffeur'}
                  roundFormField={true}
                />
              </div>
            </Col>
          </div>
          <div className='partner-filters-cta-footer'>
            <span
              onClick={() => {
                setShowFilterModal(false)
                onClearFilters()
              }}
            >
              Clear all
            </span>
            <Button
              className='select-btn select-vehicle'
              style={{
                width: '40%',
                height: '45px',
                fontWeight: '900',
                fontSize: '16px',
                textShadow: '0.25px 0 #ffffff',
              }}
              variant='brown'
              onClick={() => {
                setShowFilterModal(false)
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
      {isPartner !== null && (
        <SmallDown>
          <div className='sidebar-bottom-container sidebar-bottom-container-partner'>
            <BootMenuItem
              key='homePage'
              icon={
                isHomePageSelected
                  ? 'icons/icon-home-black.png'
                  : 'icons/icon-home-grey.png'
              }
              currentPath={currentLocation}
              path='/home-page'
              title='Home'
              onClickHandle={() => {
                history.replace(
                  `${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }driver/app/${token}/home-page`
                )
              }}
            />
            <BootMenuItem
              key='booking'
              icon={
                isBookingsSelected
                  ? 'icons/icon-bookings-black.png'
                  : 'icons/icon-bookings-grey.png'
              }
              isBooking={true}
              currentPath={currentLocation}
              path={['/new-bookings', '/completed', '/planned']}
              title='Bookings'
              onClickHandle={() => {
                history.replace(
                  `${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }driver/app/${token}/new-bookings`
                )
              }}
            />
            <BootMenuItem
              key='account'
              icon={
                isAccountSelected
                  ? 'menu_account_active.png'
                  : 'menu_account_inactive.png'
              }
              title='Account'
              currentPath={currentLocation}
              path='/account'
              onClickHandle={() => {
                history.replace(
                  isPartner
                    ? `${
                        Host === partnerHostLive || Host === partnerHostStaging
                          ? '/'
                          : '/partner/'
                      }driver/app/${token}/chauffeurs/account`
                    : `${
                        Host === partnerHostLive || Host === partnerHostStaging
                          ? '/'
                          : '/partner/'
                      }driver/app/${token}/chauffeursOnly/account`
                )
              }}
            />
          </div>
        </SmallDown>
      )}
      {showWhatsNew &&
        renderPopUp &&
        whatsNewPartner &&
        !!whatsNewPartner.title &&
        !!whatsNewPartner.description &&
        renderPopUp()}
      {renderTerms()}
      {renderEarningPopup()}
    </div>
  )
}

const mapStateToProps = ({
  auth: {
    isPartner = null,
    isDriver = null,
    isChauffeur = null,
    isCarRental = null,
    partnerName = null,
    isLocalSupplier = null,
    whatsNewPartner = null,
    showSwitchBanner = null,
    companyId = null,
    company,
    user = null,
    showEarningIndicator,
  },
}) => ({
  isPartner,
  isDriver,
  isChauffeur,
  isCarRental,
  partnerName,
  isLocalSupplier,
  whatsNewPartner,
  showSwitchBanner,
  company,
  companyId,
  user,
  showEarningIndicator,
})

export default withRouter(
  connect(mapStateToProps, {
    logout,
    getPartnerDetails,
    markPopUpAsSeenPartner,
    updatePartnerBanner,
    updateEarningInfo,
    setMessageOpen,
    setMessageClose,
  })(DefaultDriverLayout)
)
