import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Step from './Step'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { SmallDown, SmallUp } from './Responsive/breakpoints'
import FormField from './FormField'
import LocationAutocomplete from './LocationAutocomplete'
import { Col } from 'react-bootstrap'
import _ from 'lodash'
import { useGetTableData } from 'hooks'
import FormSelectCurrency from './FormSelectCurrency'
import { currencies } from 'constants/CurrencyCountry'
import FormCheckbox from './FormCheckbox'

const currencyName = str => {
  return str.toLowerCase()
}

const currencyList = () => {
  var list = []
  _.each(currencies, item => {
    list.push({
      label: item.description,
      value: `${item.description} ${currencyName(item.code)}`,
      code: item.code,
    })
  })
  return list
}

const bankFields = [
  {
    name: 'country',
    label: 'Country*',
    component: LocationAutocomplete,
    searchOptions: {
      types: ['administrative_area_level_3', 'political', 'locality'],
    },
    showPin: true,
    confirmOnSelect: true,
    needFullAddress: true,
    displayKey: 'fullAddress',
    md: 4,
  },
  {
    name: 'currency',
    label: 'Currency*',
    component: FormSelectCurrency,
    isSearchable: true,
    options: currencyList(),
    showLabel: true,
    md: 4,
    className: 'custom-white-background',
  },
  {
    name: 'legalName',
    label: 'Account name*',
    component: FormField,
    md: 8,
    className: 'px-2',
  },
  {
    name: 'iban',
    label: 'iBan*',
    component: FormField,
    md: 8,
    className: 'px-2',
  },
  {
    name: 'swiftCode',
    label: 'Swift code*',
    component: FormField,
    md: 8,
    className: 'px-2',
  },
  {
    name: 'accountNumber',
    label: 'Acount number*',
    component: FormField,
    md: 8,
    className: 'px-2',
  },
  {
    name: 'routingNumber',
    label: 'Routing number*',
    component: FormField,
    md: 8,
    className: 'px-2',
  },
  {
    name: 'sortCode',
    label: 'sort code*',
    component: FormField,
    md: 8,
    className: 'px-2',
  },
  {
    name: 'bsbCode',
    label: 'BSB code*',
    component: FormField,
    md: 8,
    className: 'px-2',
  },
  {
    name: 'institutionNumber',
    label: 'Institution Number',
    component: FormField,
    md: 8,
    className: 'px-2',
  },
  {
    name: 'transitNumber',
    label: 'Transit Number',
    component: FormField,
    md: 8,
    className: 'px-2',
  },
]

const vatCountries = ['GB', 'AE']
const gstCountries = ['AU', 'CA']
const jctCountries = ['JP']
const taxFields = (country, isEurope) => {
  // Dynamically set the label based on whether the country uses VAT or not
  const taxLabel =
    vatCountries.includes(country) || isEurope
      ? 'VAT NUMBER'
      : gstCountries.includes(country)
      ? 'GST NUMBER'
      : jctCountries.includes(country)
      ? 'JCT NUMBER'
      : country === 'US'
      ? 'TAX ID (EIN)'
      : 'TAX NUMBER'

  const registeredLabel =
    vatCountries.includes(country) || isEurope
      ? 'Business not VAT registered*'
      : gstCountries.includes(country)
      ? 'Business not GST registered*'
      : jctCountries.includes(country)
      ? 'Business not JCT registered*'
      : 'Business not tax registered*'

  return [
    {
      name: 'taxNumber',
      label: taxLabel,
      placeholder: country,
    },
    {
      name: 'taxRegistered',
      label: registeredLabel,
      groupClassName: 'com-popUp',
      component: FormCheckbox,
    },
  ]
}

export const defaultErrors = {
  sortCode: 'Sort code is required',
  legalName: 'Account name is required',
  accountNumber: 'Account number is required',
  iban: 'IBan is required',
  swiftCode: 'Swift code is required',
  taxRateId: 'Tax id is required',
  address: 'Address is required',
  addressLine2: 'Company address is required',
  postalCode: 'Zip code is required',
  place: 'City is required',
  country: 'Country is required',
  currency: 'Currency is required',
  registrationNumber: 'Registration number is required',
  taxNumber: 'Tax number is required',
}

export const ComPartnerUpdateModal = ({
  show,
  onHide,
  companyId,
  isPartner = false,
  onHandleFunction,
}) => {
  const [steps, setSteps] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [showForm, setShowForm] = useState(false)
  const [fields, setFields] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const [validationSchema, setValidationSchema] = useState([])
  const [country, setCountry] = useState('')
  const [isEurope, setIsEurope] = useState(false)
  const [taxStepTitle, setTaxStepTitle] = useState('Tax Details')
  const [prevValues, setPrevValue] = useState({})
  const [bankCountry, setBankCountry] = useState(null)
  let url = companyId ? `partner/earning-details/${companyId}` : null

  const {
    data: { list },
    isLoading,
  } = useGetTableData(url)

  const onClose = () => {
    onHide()
  }

  useEffect(() => {
    let stepName = 'Tax Details'
    if (list && list.isInEurope) {
      setIsEurope(true)
    }

    if (list && list.country) {
      if (vatCountries.includes(list.country) || list.isInEurope) {
        stepName = 'VAT Details'
        setTaxStepTitle('VAT Details')
      } else if (gstCountries.includes(list.country)) {
        stepName = 'GST Details'
        setTaxStepTitle('GST Details')
      } else if (jctCountries.includes(list.country)) {
        stepName = 'JCT Details'
        setTaxStepTitle('JCT Details')
      }
      setCountry(list.country)
    }

    //Step names
    if (list && Object.keys(list).length) {
      if (list.taxDetails && list.taxDetails.length) {
        setSteps(prevState => [...prevState, { title: stepName }])
        setFields(prevState => [...prevState, ...list.taxDetails])
      }

      if (list.bankDetails && list.bankDetails.length) {
        setSteps(prevState => [...prevState, { title: 'Bank Detail(s)' }])
        setFields(prevState => [...prevState, ...list.bankDetails])
      }

      setShowForm(true)
    }
  }, [Object.keys(list).length])

  useEffect(() => {
    //there will always be steps because steps depends on list
    if (list && Object.keys(list).length) {
      if (steps[currentStep].title === taxStepTitle) {
        let validation = Yup.object().shape({
          taxRegistered: Yup.boolean().default(false),
        })

        setInitialValues(prev => ({
          ...prev,
          ...validation.cast(),
          ...prevValues,
        }))
        setValidationSchema(validation)
      }
      if (steps[currentStep].title === 'Bank Detail(s)') {
        let validation = Yup.object().shape(
          list.bankDetails.reduce((schema, key) => {
            schema[key] =
              key === 'country' || key === 'currency'
                ? Yup.object()
                    .nullable()
                    .required(`${defaultErrors[key]}`)
                    .default(null)
                : Yup.string()
                    .required(`${defaultErrors[key]}`)
                    .default('')
            return schema
          }, {})
        )

        setInitialValues(prev => ({
          ...prev,
          ...validation.cast(),
          ...prevValues,
        }))
      }
    }
  }, [currentStep, steps.length, bankCountry])

  const onSubmit = values => {
    onHandleFunction(companyId, values)
    onClose()
  }

  return (
    <>
      {!isLoading ? (
        <Modal
          size='lg'
          show={show}
          onHide={onClose}
          className='com-popup-modal'
          dialogClassName='modal-size'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header>
            <Modal.Title
              id='example-modal-sizes-title-lg'
              className='modal-title'
            >
              <div className='close-btn-wrap'>
                <Button variant='link' onClick={onClose}>
                  <img src='/images/icons/close-icon-black.png' alt='' />
                </Button>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='modal-body-2'>
            <h1 className='title'>
              {isPartner ? 'Earnings payout' : 'Commission payout'}
            </h1>
            <p className='modal-header-2'>
              Your entity is missing important company details. ROLZO requires{' '}
              <br></br>
              the information to verify your business and pay your{' '}
              {isPartner ? 'earnings. ' : 'commissions.'}
            </p>
            {showForm ? (
              <>
                {steps && steps.length > 1 ? (
                  <div
                    className='stepper mt-4'
                    style={
                      steps.length === 3
                        ? { width: 'calc(55.8rem - 5rem)' }
                        : steps.length === 2
                        ? { width: 'calc(74rem - 5rem)' }
                        : { width: 'calc(79rem - 2.3rem)' }
                    }
                  >
                    {steps.map((step, index) => {
                      const { title } = step
                      return (
                        <Step
                          key={String(index)}
                          title={title}
                          isQuote={true}
                          isThreeSteps={true}
                          active={index === currentStep}
                          isFirstIndex={index === 0}
                        />
                      )
                    })}
                  </div>
                ) : (
                  <></>
                )}
                <Formik
                  onSubmit={onSubmit}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  validateOnBlur={false}
                  validateOnChange={false}
                  isInitialValid
                >
                  {({
                    values,
                    validateForm,
                    setTouched,
                    setFieldError,
                    handleChange,
                  }) => {
                    setPrevValue(values)
                    const onNextStep = async (
                      values,
                      validateForm,
                      setTouched
                    ) => {
                      const errors = await validateForm()
                      if (Object.keys(errors).length === 0) {
                        setCurrentStep(currentStep + 1)
                      } else {
                        // There are validation errors, set all fields as touched to show errors
                        setTouched(
                          Object.keys(values).reduce(
                            (acc, key) => ({ ...acc, [key]: true }),
                            {}
                          )
                        )
                      }
                    }

                    return (
                      <Form noValidate className='form-wrap booking-form-wrap'>
                        <div className='modal-fields mt-4'>
                          <SmallUp>
                            {steps.length &&
                            steps[currentStep] &&
                            steps[currentStep].title === taxStepTitle ? (
                              <div className='d-flex flex-column align-items-center modal-fields'>
                                <Col
                                  md={8}
                                  style={{ padding: '2.9rem 0rem 1rem 1rem' }}
                                >
                                  <span className='modal-message'>
                                    {vatCountries.includes(country) || isEurope
                                      ? `Add your company's VAT details`
                                      : gstCountries.includes(country)
                                      ? `Add your company's GST details`
                                      : jctCountries.includes(country)
                                      ? `Add your company's JCT details`
                                      : `Add your company's tax details`}
                                    {/* Add your company's tax id */}
                                  </span>
                                </Col>

                                {taxFields(country, isEurope).map(
                                  ({ component = FormField, ...field }, f) => {
                                    // if (fields.includes(field.name)) {
                                    return (
                                      <Col
                                        key={String(f)}
                                        md={8}
                                        className='mb-2'
                                      >
                                        <Field
                                          component={component}
                                          {...field}
                                          {...(field.name === 'taxNumber'
                                            ? {
                                                disabled: values.taxRegistered,
                                              }
                                            : {})}
                                          onChange={e => {
                                            setFieldError(field.name, '')
                                            handleChange(e)
                                          }}
                                        />
                                      </Col>
                                    )
                                    // }
                                    // return null
                                  }
                                )}

                                <Col
                                  key={currentStep}
                                  md={8}
                                  className='text-center'
                                  style={{ paddingBottom: '2rem' }}
                                >
                                  {currentStep !== steps.length - 1 ? (
                                    <Button
                                      style={{
                                        marginLeft: 0,
                                        padding: '0.2rem 1.5rem',
                                      }}
                                      className='sharedBooking-and-Refund-modal-btn ml-0 w-100'
                                      variant='brown'
                                      type='button'
                                      onClick={() =>
                                        onNextStep(
                                          values,
                                          validateForm,
                                          setTouched
                                        )
                                      }
                                    >
                                      Continue
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        marginLeft: 0,
                                        padding: '0.2rem 1.5rem',
                                      }}
                                      className='sharedBooking-and-Refund-modal-btn ml-0 w-100'
                                      variant='brown'
                                      type='submit'
                                    >
                                      Submit
                                    </Button>
                                  )}
                                </Col>
                              </div>
                            ) : (
                              <></>
                            )}
                            {steps.length &&
                            steps[currentStep] &&
                            steps[currentStep].title === 'Bank Detail(s)' ? (
                              <div className=' d-flex flex-column align-items-center modal-fields'>
                                <Col
                                  md={8}
                                  style={{ padding: '2.9rem 0rem 1rem 1rem' }}
                                >
                                  <span className='modal-message'>
                                    Bank details
                                    {/* Add your company's bank details */}
                                  </span>
                                </Col>
                                {bankFields.map(
                                  ({ component = FormField, ...field }, f) => {
                                    if (fields.includes(field.name)) {
                                      return (
                                        <Col
                                          key={String(f)}
                                          md={8}
                                          className='mb-2'
                                        >
                                          <Field
                                            component={component}
                                            {...field}
                                          />
                                        </Col>
                                      )
                                    }
                                    return <></>
                                  }
                                )}
                                <Col
                                  key={currentStep}
                                  md={8}
                                  className='text-center'
                                  style={{ paddingBottom: '2rem' }}
                                >
                                  {currentStep !== steps.length - 1 ? (
                                    <Button
                                      style={{
                                        marginLeft: 0,
                                        padding: '0.2rem 1.5rem',
                                      }}
                                      className='sharedBooking-and-Refund-modal-btn ml-0 w-100'
                                      variant='brown'
                                      type='button'
                                      onClick={() =>
                                        onNextStep(
                                          values,
                                          validateForm,
                                          setTouched
                                        )
                                      }
                                    >
                                      Continue
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        marginLeft: 0,
                                        padding: '0.2rem 1.5rem',
                                      }}
                                      className='sharedBooking-and-Refund-modal-btn ml-0 w-100'
                                      variant='brown'
                                      type='submit'
                                    >
                                      Submit
                                    </Button>
                                  )}
                                </Col>
                              </div>
                            ) : (
                              <></>
                            )}
                          </SmallUp>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </>
            ) : (
              <></>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}
