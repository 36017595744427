import React, { useRef } from 'react'
import { Form } from 'react-bootstrap'
import Select, { components } from 'react-select'
import { getIn, setIn } from 'formik'
import { DropdownIndicator, MultiValueContainer } from './FormSelectComponents'
import { isMobile } from '../helpers/index.js'

const { Control, Group, Label } = Form

export default function FormSelect({
  field,
  form: {
    touched = {},
    errors = {},
    setFieldValue = () => {},
    setTouched = () => {},
  } = {},
  icon,
  options = [],
  groupProps = {},
  controlId,
  handleChange,
  isMulti = false,
  showSelectAll = false,
  isSearchable = false,
  disabled = false,
  isClearAble = false,
  hideSelectedOptions,
  placeholder = 'Select...',
  classNamePrefix = 'react-select',
  roundFormField = false,
  whiteFieldInput = false,
  increaseFontSize = false,
  defaultValue = null,
  error,
  isAutomation = false,
  ...props
}) {
  const showError =
    (!!getIn(touched, field.name) && !!getIn(errors, field.name)) || error
  const wrapperRef = useRef(null)
  const selectRef = useRef(null)
  const isMobileBrowser = isMobile()
  var timer = null

  const onChange = value => {
    setFieldValue(field.name, value)
    if (handleChange) {
      if (isClearAble) {
        handleChange(value, selectRef)
      } else {
        handleChange(value)
      }
    }
  }

  const onFocus = () => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    timer = setInterval(() => {
      if (!wrapperRef || !wrapperRef.current) {
        clearInterval(timer)
        return
      }
      // let react_menu = wrapperRef.current.getElementsByClassName(
      //   `${classNamePrefix}__menu`
      // )
      // if (react_menu.length > 0) {
      // wrapperRef.current.style.marginBottom =
      //   react_menu[0].scrollHeight + 'px'
      // } else {
      //   wrapperRef.current.removeAttribute('style')
      // }
    }, 100)
  }

  const onBlur = () => {
    const _touched = setIn({ ...touched }, field.name, true)
    setTouched(_touched)
  }

  const onSelectAll = e => {
    e.preventDefault()
    e.stopPropagation()
    const newValue =
      !field.value || field.value.length < options.length ? [...options] : []
    setFieldValue(field.name, newValue)
  }

  const Option = props => {
    if (props.data.type === 'checkBox') {
      return (
        <div className='selectOptionCheckbox'>
          <components.Option {...props}>
            <div>
              <span>{props.data.label}</span>
            </div>
          </components.Option>
        </div>
      )
    } else if (props.data.type && props.data.type === 'button') {
      return (
        <div className='selectOptionButton'>
          <components.Option {...props}>
            <div>
              <span>{props.data.label}</span>
              <img src={`/images/icons/${props.data.icon}`} alt='' />
            </div>
          </components.Option>
        </div>
      )
    } else {
      return <components.Option {...props} />
    }
  }
  const ClearIndicator = props => {
    return (
      components.ClearIndicator && (
        <components.ClearIndicator {...props}>
          <div
            className={`${classNamePrefix}__dropdown-indicator-clearable-filter`}
          >
            <img src='/images/icons/close-icon.png' alt='' />
          </div>
        </components.ClearIndicator>
      )
    )
  }
  const DropdownIndicatorClearable = props => {
    if (props.hasValue) {
      return null
    } else {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <div
              className={`${classNamePrefix}__dropdown-indicator-carrot-filter`}
            >
              <img src='/images/icons/caret-down.svg' alt='' />
            </div>
          </components.DropdownIndicator>
        )
      )
    }
  }
  const ValueContainer = isMulti
    ? MultiValueContainer
    : components.ValueContainer
  return (
    <>
      <Group
        controlId={controlId || field.name}
        ref={wrapperRef}
        className={
          whiteFieldInput
            ? 'white-field-input'
            : roundFormField
            ? 'round-select-group'
            : increaseFontSize
            ? 'client-select-group'
            : isAutomation
            ? 'automation-time-select'
            : ''
        }
        style={isAutomation ? { marginBottom: '0' } : {}}
      >
        {props.label && <Label className='form-label'>{props.label}</Label>}
        <Select
          components={
            isClearAble
              ? {
                  DropdownIndicator: DropdownIndicatorClearable,
                  IndicatorSeparator: () => null,
                  ValueContainer,
                  ClearIndicator,
                  Option,
                }
              : { DropdownIndicator, ValueContainer, Option }
          }
          className={`${classNamePrefix}-container ${
            showError ? 'is-invalid' : ''
          } ${
            selectRef &&
            selectRef.current &&
            selectRef.current.state.value &&
            classNamePrefix !== 'react-select'
              ? 'hasValue'
              : ''
          }`}
          classNamePrefix={classNamePrefix}
          hideSelectedOptions={
            hideSelectedOptions !== undefined ? !!hideSelectedOptions : !isMulti
          }
          defaultValue={defaultValue}
          {...field}
          inputId={controlId || field.name}
          ref={selectRef}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          isClearable={isClearAble}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          onSelectAll={showSelectAll ? onSelectAll : null}
          isSearchable={isSearchable}
          selectedAll={
            !!showSelectAll &&
            !!isMulti &&
            !!field.value &&
            field.value.length === options.length
          }
          isDisabled={disabled}
          // menuIsOpen={true}
        />
        {showError && (
          <Control.Feedback className='d-block' type='invalid'>
            {getIn(errors, field.name)}
          </Control.Feedback>
        )}
      </Group>
      {/* )} */}
    </>
  )
}
