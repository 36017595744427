import { fileToBase64, getLabelFromValue, getOptionFromValue } from '../helpers'
import {
  defaultCurrenciesFour,
  defaultPaymentTerms,
  defaultRateTypes,
  defaultBookingAccess,
  defaultCompanyStatus,
  defaultCurrencies,
  defaultCompanySizes,
  defaultTaxStatus,
} from '../constants/index.js'
import _ from 'lodash'
import { logger } from '../actions'
export const companyClientFormData = async ({
  logo,
  contactPhoneNumber1 = '',
  contactPhoneNumber2 = '',
  contact2PhoneNumber1 = '',
  contact2PhoneNumber2 = '',
  currency,
  paymentTerms,
  rateTypes,
  address,
  id,
  // teamPrivacy,
  bookingAccess,
  customerInvoice,
  showPriceAndDiscount,
  noPriceInvoice,
  showPriceWithoutDiscount,
  noCreditCard,
  driverUpdateByEmail,
  bookingUpdatesByEmail,
  driverUpdateByPushNotification,
  bookingUpdateByPushNotification,
  supportMessagesByEmail,
  supportMessagesByPushNotification,
  tenMinsAwayDriverUpdate,
  notificationSettings,
  markupWeight,
  companyLabel,
  ...values
}) => {
  try {
    let data = {
      ...values,
      contactPhones: [contactPhoneNumber1, contactPhoneNumber2],
      contact2Phones: [contact2PhoneNumber1, contact2PhoneNumber2],
      currency: currency ? currency.value : '',
      paymentTerms: paymentTerms ? paymentTerms.value : '',
      rateTypes: rateTypes ? rateTypes.value : '',
      // teamPrivacy: teamPrivacy ? teamPrivacy.value : '',
      bookingAccess: bookingAccess ? bookingAccess.value : '',
      noCreditCard: noCreditCard ? noCreditCard : false,
      customerInvoice: customerInvoice ? customerInvoice : false,
      showPriceAndDiscount: showPriceAndDiscount ? showPriceAndDiscount : false,
      noPriceInvoice: noPriceInvoice ? noPriceInvoice : false,
      showPriceWithoutDiscount: showPriceWithoutDiscount
        ? showPriceWithoutDiscount
        : false,
      notificationSettings: {
        driverUpdateByEmail: driverUpdateByEmail ? driverUpdateByEmail : false,
        bookingUpdatesByEmail: bookingUpdatesByEmail
          ? bookingUpdatesByEmail
          : false,
        driverUpdateByPushNotification: driverUpdateByPushNotification
          ? driverUpdateByPushNotification
          : false,
        bookingUpdateByPushNotification: bookingUpdateByPushNotification
          ? bookingUpdateByPushNotification
          : false,
        supportMessagesByEmail: supportMessagesByEmail
          ? supportMessagesByEmail
          : false,
        supportMessagesByPushNotification: supportMessagesByPushNotification
          ? supportMessagesByPushNotification
          : false,
        tenMinsAwayDriverUpdate: tenMinsAwayDriverUpdate
          ? tenMinsAwayDriverUpdate
          : false,
      },
      markupWeight: markupWeight ? markupWeight : 0,
      companyLabel: companyLabel ? companyLabel : 'new',
    }

    if (address) {
      data.address = address.fullAddress || ''
    }

    if (logo && typeof logo === 'object' && logo.size) {
      const logoBase64 = await fileToBase64(logo)
      data.logo = logoBase64 || null
    }

    if (!id) {
      data.active = true
    }

    return data
  } catch (error) {
    logger({ fileName: 'companyClients', error: error })
    console.log(error)
    return {}
  }
}

const industries = [
  { label: 'Travel', value: 'Travel' },
  { label: 'Lifestyle Management', value: 'Lifestyle Management' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Aviation', value: 'Aviation' },
  { label: 'Corporate', value: 'Corporate' },
  { label: 'Luxury Retail', value: 'Luxury Retail' },
  { label: 'Entertainment', value: 'Entertainment' },
  { label: 'Events Services', value: 'Events Services' },
  { label: 'Other', value: 'Other' },
]
const currencyName = str => {
  return str.toLowerCase()
}
export class CompanyClientInitialValues {
  constructor(
    {
      name = '',
      verified = false,
      addressLine2 = '',
      address = '',
      registrationNumber = '',
      notes = '',
      logo = '',
      contactEmail = '',
      contactFullName = '',
      contactPhones = [],
      contact2Email = null,
      contact2FullName = null,
      contact2Phones = null,
      currency,
      taxName,
      taxValue,
      taxNumber,
      postalCode = '',
      postalCode2 = '',
      paymentTerms,
      rateTypes,
      weight,
      place = null,
      id = null,
      active = true,
      // teamPrivacy,
      bookingAccess,
      noCreditCard,
      googleLogo,
      customerInvoice,
      showPriceAndDiscount,
      noPriceInvoice,
      showPriceWithoutDiscount,
      chauffeurDetails,
      realTimeDetailsToPassenger,
      deferredPayment,
      deferredPaymentCard,
      standardPayment,
      standardPaymentWithLink,
      useInternalBookingId,
      companySize,
      issueNetInvoice,
      industry,
      consortiums = [],
      affiliatedWithHostCompanyName,
      isHost,
      personalisedNameboard,
      rolzoNameBoard,
      internalBookingLabel,
      mandatoryInternalField,
      notificationSettings,
      currencySettings,
      ccEmails = [],
      markupWeight,
      companyLabel = '',
      isRolzoFirstMember,
      website = '',
      billing = {
        name: '',
        contacts: [{ fullName: '', email: '' }],
        currency: { label: '', value: '' },
        taxNumber: '',
        taxRate: 0,
        isTaxRegistered: false,
        registrationNumber: '',
        address: null,
        city: null,
        address2: '',
        postalCode: '',
      },
    } = {},
    currencies
  ) {
    var list = []
    if (currencies) {
      _.each(currencies, item => {
        list.push({
          label: item.description,
          value: currencyName(item.code), //currency_afn
        })
      })
    }
    const foundCurrency = getOptionFromValue(defaultCurrenciesFour, currency)
    let platform = {
      'https://business.rolzo.com/api': 'business',
      'https://staging.rolzo.com/api': 'staging',
      'http://localhost:3000': 'staging',
      'https://demo.rolzo.com/api': 'demo',
    }
    const foundPaymentTerms = getOptionFromValue(
      defaultPaymentTerms,
      paymentTerms
    )
    // const foundTeamPrivacy = getOptionFromValue(defaultTeamPrivacy, teamPrivacy)
    const foundBookingAccess = getOptionFromValue(
      defaultBookingAccess,
      bookingAccess
    )
    const foundRateTypes = getOptionFromValue(defaultRateTypes, rateTypes)
    this.name = name
    this.companySize = companySize
      ? getOptionFromValue(defaultCompanySizes, companySize)
      : ''
    this.industry = industry ? getOptionFromValue(industries, industry) : ''
    this.consortiums =
      consortiums && consortiums.length ? consortiums : [{ name: '' }]
    this.affiliatedWithHostCompanyName = affiliatedWithHostCompanyName
      ? affiliatedWithHostCompanyName
      : ''
    this.isHost = isHost ? true : false
    this.addressLine2 = addressLine2
    this.registrationNumber = registrationNumber
    this.notes = notes
    this.logo = logo
      ? {
          name: logo,
        }
      : null
    this.contactEmail = contactEmail
    this.contactFullName = contactFullName
    this.contactPhoneNumber1 = contactPhones ? contactPhones[0] || '' : ''
    this.contactPhoneNumber2 = contactPhones ? contactPhones[1] || '' : ''
    this.contact2Email = contact2Email
    this.contact2FullName = contact2FullName
    this.contact2PhoneNumber1 = contact2Phones ? contact2Phones[0] || '' : ''
    this.contact2PhoneNumber2 = contact2Phones ? contact2Phones[1] || '' : ''
    this.issueNetInvoice = issueNetInvoice ? issueNetInvoice : false
    this.currency = foundCurrency
    this.taxName = taxName
    this.taxValue = taxValue
    this.taxNumber = taxNumber
    this.postalCode = postalCode
    this.postalCode2 = postalCode2
    this.paymentTerms = foundPaymentTerms
    // this.teamPrivacy = foundTeamPrivacy
    this.googleLogo = googleLogo
      ? `https://storage.googleapis.com/rolzo-platform/${
          platform[process.env.REACT_APP_APIHOST]
        }/images/${googleLogo}`
      : null
    this.bookingAccess = foundBookingAccess
    this.rateTypes = foundRateTypes
    this.weight = weight
    this.noCreditCard = noCreditCard
    this.customerInvoice = customerInvoice
    this.showPriceAndDiscount = showPriceAndDiscount
    this.noPriceInvoice = noPriceInvoice
    this.showPriceWithoutDiscount = showPriceWithoutDiscount
    this.chauffeurDetails = chauffeurDetails
    this.realTimeDetailsToPassenger = realTimeDetailsToPassenger
    this.deferredPayment = deferredPayment
    this.deferredPaymentCard = deferredPaymentCard
    this.standardPayment = standardPayment
    this.standardPaymentWithLink = standardPaymentWithLink
    this.personalisedNameboard = personalisedNameboard
    this.rolzoNameBoard = rolzoNameBoard
    this.useInternalBookingId = useInternalBookingId
    this.internalBookingLabel = internalBookingLabel ? internalBookingLabel : ''
    this.mandatoryInternalField = mandatoryInternalField
      ? mandatoryInternalField
      : false
    this.place =
      place && place.cityState
        ? place
        : { ...place, cityState: `${place.city}, ${place.state}` }
    this.driverUpdateByEmail = notificationSettings.driverUpdateByEmail
    this.bookingUpdatesByEmail = notificationSettings.bookingUpdatesByEmail
    this.driverUpdateByPushNotification =
      notificationSettings.driverUpdateByPushNotification
    this.bookingUpdateByPushNotification =
      notificationSettings.bookingUpdateByPushNotification
    this.supportMessagesByEmail = notificationSettings.supportMessagesByEmail
    this.supportMessagesByPushNotification =
      notificationSettings.supportMessagesByPushNotification
    this.tenMinsAwayDriverUpdate = notificationSettings.tenMinsAwayDriverUpdate
    if (currencies) {
      this.multipleCurrency =
        currencySettings.currencyMethod === 'multipleCurrency' ? true : false
      this.singleCurrency =
        currencySettings.currencyMethod === 'singleCurrency' ? true : false
      if (currencySettings.currencyType) {
        this.currencyChoice = list.find(item => {
          if (item.value === currencySettings.currencyType.split('_')[1]) {
            return {
              label: item.label,
              value: `${item.label} ${
                currencySettings.currencyType.split('_')[1]
              }`,
            }
          }
        })
      }
    }
    this.address = {
      fullAddress: address,
    }
    this.id = id
    if (!id) {
      this.active = active
    }
    this.ccEmails = ccEmails
    this.markupWeight = markupWeight
    this.companyLabel = companyLabel
      ? {
          label: getLabelFromValue(defaultCompanyStatus, companyLabel),
          value: companyLabel,
        }
      : { label: 'INACTIVE', value: 'inactive' }
    this.billing = {
      name: billing.name || '',
      contacts: billing.contacts || [{ fullName: '', email: '' }],
      taxNumber: billing.taxNumber || '',
      taxRate: billing.taxRate || 0,
      currency: billing.currency
        ? {
            label: getLabelFromValue(defaultCurrencies, billing.currency),
            value: billing.currency,
          }
        : { label: '', value: '' },
      registrationNumber: billing.registrationNumber || '',
      isTaxRegistered: billing.isTaxRegistered || false,
      address: billing.address || null,
      city: billing.city || null,
      address2: billing.address2 || '',
      postalCode: billing.postalCode || '',
    }
    this.isRolzoFirstMember = isRolzoFirstMember ? isRolzoFirstMember : false
    this.website = website ? website : ''
    this.verified = verified ? true : false
  }
}

export const billingFormData = async ({
  legalName,
  accountNumber,
  iban,
  sortCode,
  country,
  currency,
  addressLine2,
  registrationNumber,
  taxNumber,
  taxRegistered,
}) => {
  try {
    let data = {
      bankDetails: {},
      billing: {},
      addressLine2: addressLine2,
    }

    if (legalName) {
      data.bankDetails = {
        legalName,
        accountNumber,
        iban,
        sortCode,
        country,
        currency,
      }
    }

    if (taxNumber || taxRegistered || registrationNumber) {
      data.billing = {
        taxNumber,
        taxRegistered,
        registrationNumber,
      }
    }

    return data
  } catch (error) {
    logger({ fileName: 'comBillingFormData', error: error })
    console.log(error)
    return {}
  }
}
